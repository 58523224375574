import { GridRenderCellParams } from '@mui/x-data-grid/models/params/gridCellParams';
import useWidget from '@pages/AdditionalServices/context';
import FieldCellContainer from '@pages/AdditionalServices/tabs/AdditionalForm/components/AdditionalServiceContainer/components/AdditionalServiceIsVariantListing/components/AdditionalServicesTable/components/StyledComponents';
import React from 'react';

const TotalField = (props: GridRenderCellParams<any, any, any>) => {
  const { row } = props;
  const [basket] = useWidget().useBasketAdditionalService().useState([props.row.id]);
  const [{ currencies }] = useWidget().useAdditionalInfo().useState();

  const result = basket[row.id];

  const currentCurrency =
    currencies.find((c) => Number(result?.currencyId || row.totalCurrency) === Number(c.id)) || '';

  return (
    <FieldCellContainer>
      {row.total.toFixed(2)}
      {currentCurrency && ` ${currentCurrency.code}`}
    </FieldCellContainer>
  );
};

export default TotalField;
