import { BasketAdditionalService } from '@pages/AdditionalServices/context/types';
import { ServicesProps } from '@services/requests/servicesService/servicesServicesService/interface';

import { TemplateParams } from '../interface';
import { getColumnsForAdditionalService } from './DataForTableGenerator';
import { getRowsForTemplate4 } from './RowGeneratorTemplate4';

export const getChildrenGridDataTemplate1 = (
  id: string,
  service: ServicesProps,
  basket: BasketAdditionalService,
  templateParams: TemplateParams
) => {
  const {
    additionalTemplateParams: { enabled, items },
    type: templateType,
  } = templateParams;
  const templateConfig = enabled ? items : [];

  const offerFormula = service.price_offers.map((offer) => offer.formula).join('|');
  const filteredAllFieldsByUsage = service.allFields
    .filter((field) => offerFormula.includes(field.code))
    .filter((field) => {
      const settings = field.hasOwnProperty('settings') ? JSON.parse(field.settings) : false;

      return settings && settings.editable;
    });
  const filteredAllFieldsWithoutPaymentValue = filteredAllFieldsByUsage.filter(
    (f) => !f.code.includes('paymentvalue')
  );

  const rows = getRowsForTemplate4(service, templateParams, basket[id]);
  const columns = getColumnsForAdditionalService(
    service,
    templateType,
    filteredAllFieldsWithoutPaymentValue,
    templateConfig
  );

  return [{ rows, columns }];
};
