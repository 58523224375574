import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

import ServicesName from '../../ServicesName';
import CountField from '../../Templates/Template4/CountField';
import RowContainer from '../StyledComponents';

function Template4Placeholder(props: any) {
  const { t } = useTranslation('AdditionalService');
  const { isIncludedInTheBasicService, templateConfig } = props.row;
  const styles = templateConfig?.[1]?.styles ?? '';

  const foundField = (
    Object.values(props.row).filter((value) => typeof value === 'object') as any[]
  ).find((prop) => prop.code?.includes(props.field));
  const settings =
    foundField && foundField.hasOwnProperty('settings') ? JSON.parse(foundField.settings) : false;
  const isCountEditable = settings && settings.editable;
  const dirFieldCodes = props.row.dirFieldCodes;

  const placeholderText = isIncludedInTheBasicService
    ? t('AdditionalService:IncludedInTheBasicService')
    : t('AdditionalService:CalculationOnRequest');

  const formattedStyles = props.visibleColumns.map((col) => ({
    minWidth: col.minWidth,
    maxWidth: col.maxWidth,
    width: col.computedWidth,
  }));

  const className = styles.length > 0 ? `cost-cell-${props.row.id}` : '';

  if (!isCountEditable) {
    return (
      <RowContainer className={'MuiDataGrid-row'}>
        <div className={'cell'} style={{ ...formattedStyles[0] }}>
          <ServicesName {...props} />
        </div>
        <style>{` .cost-cell-${props.row.id} { ${styles} } `}</style>
        <div className={clsx('cell', className)} style={{ ...formattedStyles[1] }}>
          {placeholderText}
        </div>
        {props.visibleColumns.map((column, index) => {
          if (dirFieldCodes.includes(column.field)) {
            const styles = templateConfig?.[index]?.styles ?? '';
            const className = styles.length > 0 ? `${column.field}-cell-${props.row.id}` : '';
            return (
              <React.Fragment key={`template-4-cell-${index}`}>
                <style>{` .${column.field}-cell-${props.row.id} { ${styles} } `}</style>
                <div
                  className={clsx('cell', className)}
                  key={`${props.row.id}-${index}`}
                  style={{ ...formattedStyles[index] }}
                >
                  <CountField {...props} field={column.field} />
                </div>
              </React.Fragment>
            );
          }
        })}
        <div className={'cell'} style={{ flex: 1 }}>
          {placeholderText}
        </div>
      </RowContainer>
    );
  }

  return (
    <RowContainer className={'MuiDataGrid-row'}>
      <div className={'cell'} style={{ ...formattedStyles[0] }}>
        <ServicesName {...props} />
      </div>
      <style>{` .cost-cell-${props.row.id} { ${styles} } `}</style>
      <div className={clsx('cell', className)} style={{ ...formattedStyles[1] }}>
        {placeholderText}
      </div>
      <div className={'cell'} style={{ ...formattedStyles[2] }}>
        <CountField {...props} />
      </div>
      <div className={'cell'} style={{ flex: 1 }}>
        {placeholderText}
      </div>
    </RowContainer>
  );
}

export { Template4Placeholder };
