import TextField from '@mui/material/TextField';
import { useGridApiContext } from '@mui/x-data-grid';
import { GridRenderCellParams } from '@mui/x-data-grid/models/params/gridCellParams';
import useWidget from '@pages/AdditionalServices/context';
import { FieldState } from '@pages/AdditionalServices/context/types';
import { SetterContext, StateUpdater } from '@settings/core/stateContexts/types';
import React, { CSSProperties, useEffect, useState } from 'react';

import { UnitCell } from './UnitCell';

const style: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  justifyContent: 'center',
  textAlign: 'center',
};

const CountField = (props: GridRenderCellParams<any, any, any>) => {
  const { row } = props;
  const { templateConfig } = row;

  const styles = templateConfig?.find((config) => config.field === props.field)?.styles ?? '';

  const apiRef = useGridApiContext();

  const { useFieldStateServices, useFieldStateMainForm, actions } = useWidget();
  const { recalculateServices } = actions;
  const [states, setStates] = useFieldStateServices().useState();
  const [_, setMainState] = useFieldStateMainForm().useState();
  const mergedFields = [...row.fieldCodeInUse, ...row.groupFieldCodesInUse];
  const isAllFieldsDir =
    mergedFields.length > 0 ? mergedFields.every((f) => row.dirFieldCodes.includes(f)) : false;
  const fields =
    row.fieldCodeInUse.length < row.groupFieldCodesInUse.length || isAllFieldsDir
      ? row.fieldCodeInUse
      : row.groupFieldCodesInUse;

  const isFieldInUse = fields.includes(props.field);
  const filteredRowValues = Object.values(row).filter(
    (value) => typeof value === 'object' && !Array.isArray(value)
  ) as any[];

  const [foundFieldKey, foundFieldValue, foundFieldHelper] = filteredRowValues.reduce(
    (prev, prop) => {
      const settings =
        prop && !Array.isArray(prop) && prop.hasOwnProperty('settings')
          ? JSON.parse(prop.settings)
          : false;
      const isUsageDependencyEnabled =
        settings &&
        settings.hasOwnProperty('usageDependencyParams') &&
        settings.usageDependencyParams.enabled;

      if (!isUsageDependencyEnabled) {
        return prev;
      }
      return [settings.usageDependencyParams.field, prop.value, prop.helper];
    },
    [null, null]
  );

  const isHiddenCount =
    foundFieldKey && foundFieldKey !== props.field && foundFieldKey.includes(props.field);

  const latestStateValue =
    states[row.id][isHiddenCount ? foundFieldKey : props.field]?.value.toString() ?? '0';

  const [value, setValue] = useState<string>(latestStateValue);
  const [isInit, setIsInit] = useState<boolean>(true);

  useEffect(() => {
    setValue(latestStateValue);
  }, [latestStateValue]);

  const setState = (value: SetterContext<FieldState>) => {
    const serviceIds = Object.keys(states);

    if (typeof value !== 'function') {
      setStates((prevState) => ({
        ...prevState,
        [row.id]: value,
      }));

      recalculateServices(serviceIds);
      return;
    }

    const stateUpdater = value as StateUpdater<FieldState>;
    setStates((prevState) => {
      const newState = { ...prevState };
      serviceIds.forEach((id) => {
        newState[id] = stateUpdater(states[id]);
      });
      return newState;
    });

    recalculateServices(serviceIds);
  };

  const onChangeCount = (value: string) => {
    if (value === '0' || value === '') {
      setValue('0');
      setState((prevState: FieldState) => ({
        ...prevState,
        [props.field]: {
          ...prevState[props.field],
          value: '0',
        },
      }));
      setMainState((prevState) => ({
        ...prevState,
        [props.field]: { ...prevState[props.field], value: 0 },
      }));
      return;
    }

    const fieldValue = apiRef.current.getCellValue(row.id, props.field);

    const settings =
      fieldValue && fieldValue.hasOwnProperty('settings') ? JSON.parse(fieldValue.settings) : false;
    const isLinkDependencyEnabled =
      settings &&
      settings.hasOwnProperty('linkDependencyParams') &&
      settings.linkDependencyParams.enabled;
    let add = 0;
    let reduce = 0;

    if (isLinkDependencyEnabled) {
      add = settings.linkDependencyParams?.add ?? 0;
      reduce = settings.linkDependencyParams?.reduce ?? 0;
    }

    let addMain = 0;
    let reduceMain = 0;

    const field = settings?.linkDependencyParams?.field ?? props.field;

    if (foundFieldKey === field && !isInit) {
      const field = row[foundFieldKey];
      const settings =
        field && field.hasOwnProperty('settings') ? JSON.parse(field.settings) : false;
      const isLinkDependencyEnabled =
        settings &&
        settings.hasOwnProperty('linkDependencyParams') &&
        settings.linkDependencyParams.enabled;

      if (isLinkDependencyEnabled) {
        addMain = settings.linkDependencyParams?.add ?? 0;
        reduceMain = settings.linkDependencyParams?.reduce ?? 0;
      }
    }

    setValue(value);
    setState((prevState: FieldState) => {
      let newState = {
        ...prevState,
        [props.field]: {
          ...prevState[props.field],
          value: (+value + addMain - reduceMain).toString(),
        },
      };
      if (isHiddenCount || isLinkDependencyEnabled) {
        const key = foundFieldKey ?? settings.linkDependencyParams.field;

        if (foundFieldKey === settings.linkDependencyParams.field && !isInit) {
          add = 0;
          reduce = 0;
        }

        newState = {
          ...newState,
          [key]: {
            ...newState[key],
            value: (+value + add - reduce).toString(),
          },
        };
      }

      return newState;
    });
    setMainState((prevState) => ({
      ...prevState,
      [props.field]: { ...prevState[props.field], value: +value + addMain - reduceMain },
    }));
    setIsInit(false);
  };

  useEffect(() => {
    if (foundFieldKey && foundFieldKey !== props.field && foundFieldKey.includes(props.field)) {
      const newInitValue = states[row.id][props.field].value.toString();
      setValue(newInitValue);
      onChangeCount(newInitValue);
    }
  }, []);

  const helper = row[props.field].helper;
  const name = row[props.field].name;
  const stlyes = ` .${props.field}-cell-${row.id} { ${styles} } `;
  const additionClassName = `${props.field}-cell-${row.id}`;

  if (foundFieldKey && foundFieldKey === props.field) {
    return (
      <UnitCell
        name={name}
        helper={foundFieldHelper}
        style={stlyes}
        additionClassName={additionClassName}
        render={
          <div className={`${props.field}-cell-${row.id}`} style={style}>
            {foundFieldValue}
          </div>
        }
      />
    );
  }

  if (foundFieldKey && foundFieldKey.includes(props.field)) {
    return (
      <UnitCell
        name={name}
        helper={helper}
        style={stlyes}
        additionClassName={additionClassName}
        render={
          <div className={`${props.field}-${row.id}`} style={style}>
            <TextField
              fullWidth
              size={'small'}
              InputLabelProps={{ shrink: true }}
              type={'number'}
              label={''}
              variant="outlined"
              value={value}
              onChange={(event) => onChangeCount(event.target.value)}
              sx={{ '.MuiInputBase-input': { textAlign: 'center' } }}
            />
          </div>
        }
      />
    );
  }

  if (!isFieldInUse && !row.isSectionAdditionalServices && isAllFieldsDir) {
    return (
      <UnitCell
        name={name}
        helper={helper}
        style={stlyes}
        additionClassName={additionClassName}
        render={
          <div className={`${props.field}-${row.id}`} style={style}>
            -
          </div>
        }
      />
    );
  }

  return (
    <UnitCell
      name={name}
      helper={helper}
      style={stlyes}
      additionClassName={additionClassName}
      render={
        <div className={`${props.field}-${row.id}`} style={style}>
          <TextField
            fullWidth
            size={'small'}
            InputLabelProps={{ shrink: true }}
            type={'number'}
            label={''}
            variant="outlined"
            value={isHiddenCount ? states[row.id][foundFieldKey] : value}
            onChange={(event) => onChangeCount(event.target.value)}
            sx={{ '.MuiInputBase-input': { textAlign: 'center' } }}
          />
        </div>
      }
    />
  );
};

export default CountField;
