import {
  ServicesProps,
  ServicesServicePriceCalculationMutationProps,
} from '@services/requests/servicesService/servicesServicesService/interface';

export const getRowsForTemplate3 = (
  s: ServicesProps,
  count: number,
  existCount?: number,
  serviceData?: ServicesServicePriceCalculationMutationProps
) => {
  const childs = s.childs;

  const coursePurchase =
    childs.find((child) =>
      child.fields.find((field) => field.code.includes('isCoursePurchaseTemp3'))
    )?.id || '';
  const currencyTransfer =
    childs.find((child) =>
      child.fields.find((field) => field.code.includes('isCurrencyTransferTemp3'))
    )?.id || '';
  const exchangeControl =
    childs.find((child) =>
      child.fields.find((field) => field.code.includes('isExchangeControlTemp3'))
    )?.id || '';
  const agentServices =
    childs.find((child) =>
      child.fields.find((field) => field.code.includes('isAgentServicesTemp3'))
    )?.id || '';
  const calculatedId = s.id.includes('.payment') ? s.id : `${s.id}.payment-0`;

  let item = {
    type: 'regular',
    id: s.id,
    name: s.name,
    cost: 0,
    count: 1,
    sum: 0,
    course: 0,
    conversePayment: 0,
    sumConversePayment: 0,
    totalNoTax: 0,
    taxRate: 0,
    sumTax: 0,
    total: 0,
    calculationAccordingToApplication: true,
    isCalculationOnRequest: false,
    isIncludedInTheBasicService: false,
    childServiceIdsToUpdate: [coursePurchase, currencyTransfer, exchangeControl, agentServices].map(
      (id, index) => `${id}.sub-payment-${index}-${calculatedId}`
    ),
    coursePurchaseField: coursePurchase,
    currencyTransferField: currencyTransfer,
    exchangeControlField: exchangeControl,
    agentServicesField: agentServices,
    template: s.template,
    fieldCodeInUse: [],
  };

  if (serviceData?.result?.result) {
    const {
      basePrice,
      fullPriceInTargetCurrency,
      taxInTargetCurrency,
      innerCurrency,
      priceInTargetCurrency,
    } = serviceData.result.result;

    item = {
      ...item,
      course: +innerCurrency,
      cost: basePrice,
      total: fullPriceInTargetCurrency,
      sumTax: taxInTargetCurrency,
      totalNoTax: priceInTargetCurrency,
      calculationAccordingToApplication: basePrice < 0,
      isCalculationOnRequest: basePrice === -1,
      isIncludedInTheBasicService: basePrice === 0,
    };
  }

  const temp = s.fields.reduce((acc, f) => {
    acc = {
      ...acc,
      [f.code]: f,
    };
    return acc;
  }, item);

  if (existCount === count || s.id.includes('payment')) {
    return [temp];
  }

  const payments = new Array(count)
    .fill(temp)
    .map((row, index) => ({ ...row, id: `${row.id}.payment-${index}` }));

  return payments;
};
