import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { TextFieldPropsSizeOverrides } from '@mui/material/TextField/TextField';
import Tooltip from '@mui/material/Tooltip';
import { OverridableStringUnion } from '@mui/types';
import useWidget from '@pages/AdditionalServices/context';
import { FieldState } from '@pages/AdditionalServices/context/types';
import { useDebounceStream } from '@settings/core/stateContexts/useDebounceStream';
import React, { useEffect, useState } from 'react';

import { PropsGetField } from './GetFields';

const NumberField = (props: PropsGetField) => {
  const {
    field,
    setState,
    state,
    withLabel = true,
    realTimeValidation = false,
    size = 'medium',
    isSyncWithStates,
  } = props;
  const value = state[field.code] ? state[field.code]?.value : field.value;
  const hasError = realTimeValidation ? value === undefined || value === null : false;
  const [debounceValue, simpleValue, setDebounceValue] = useDebounceStream<number>(value);
  const [firstRender, setFirstRender] = useState<boolean>(false);
  const { useFieldStateServices, actions } = useWidget();
  const { recalculateServices } = actions;
  const [states, setStates] = useFieldStateServices().useState();
  const helper = field && field.hasOwnProperty('helper') && field.helper;
  const stateValue = state[field.code].value;

  useEffect(() => {
    if (isSyncWithStates) {
      setDebounceValue(stateValue);
    }
  }, [isSyncWithStates, stateValue]);

  useEffect(() => {
    if (firstRender && state[field.code]?.value) {
      setFirstRender(true);
      return;
    }

    setState((prevState: FieldState) => ({
      ...prevState,
      [field.code]: {
        ...value[field.code],
        value: debounceValue,
      },
    }));
  }, [debounceValue]);

  const onChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;
    const regex = /^[0-9]*\.?[0-9]*$/; // Регулярное выражение для чисел (целых и с плавающей точкой);

    if (value === '0' || value === '') {
      setDebounceValue(0);
      return;
    }

    if (!regex.test(value)) {
      // Если введенное значение не соответствует шаблону и не пустое, игнорируем его
      return;
    }

    const settings = field && field.hasOwnProperty('settings') ? JSON.parse(field.settings) : false;
    const isLinkDependencyEnabled =
      settings &&
      settings.hasOwnProperty('linkDependencyParams') &&
      settings.linkDependencyParams.enabled;
    let add = 0;
    let reduce = 0;

    if (isLinkDependencyEnabled) {
      add = settings.linkDependencyParams?.add ?? 0;
      reduce = settings.linkDependencyParams?.reduce ?? 0;
    }

    setDebounceValue(Number(value));

    if (isSyncWithStates) {
      const serviceIds = Object.keys(states);

      setStates((prevState) => {
        const newState = { ...prevState };
        serviceIds.forEach((id) => {
          newState[id] = {
            ...prevState[id],
            [field.code]: {
              ...prevState[id][field.code],
              value: +value,
            },
          };
          if (isLinkDependencyEnabled) {
            const key = settings.linkDependencyParams.field;
            newState[id][key] = {
              ...newState[id][key],
              value: +value + add - reduce,
            };
          }
        });

        return newState;
      });

      recalculateServices(serviceIds);
    }
  };

  const settings = field && field.hasOwnProperty('settings') ? JSON.parse(field.settings) : false;

  return (
    <TextField
      fullWidth
      size={size as OverridableStringUnion<'small' | 'medium', TextFieldPropsSizeOverrides>}
      error={hasError}
      label={withLabel ? field.name : ''}
      InputLabelProps={{ shrink: true }}
      variant="outlined"
      value={simpleValue}
      onChange={onChange}
      disabled={!(settings && settings.editable)}
      InputProps={{
        endAdornment: helper && (
          <Tooltip title={<div dangerouslySetInnerHTML={{ __html: helper }} />}>
            <IconButton sx={{ width: 16, height: 16, backgroundColor: 'grey.100' }}>
              <QuestionMarkIcon sx={{ fontSize: 8 }} />
            </IconButton>
          </Tooltip>
        ),
      }}
    />
  );
};

export default NumberField;
