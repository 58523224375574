import React from 'react';
import { useTranslation } from 'react-i18next';

import ServicesName from '../../ServicesName';
import CostField from '../../Templates/Template5/CostField';
import RowContainer from '../StyledComponents';

function Template5Placeholder(props: any) {
  const { t } = useTranslation('AdditionalService');
  const { isIncludedInTheBasicService } = props.row;
  const countField = (
    Object.values(props.row).filter((value) => typeof value === 'object') as any[]
  ).find((prop) => prop.code?.includes('countTemp'));
  const settings =
    countField && countField.hasOwnProperty('settings') ? JSON.parse(countField.settings) : false;
  const isCountEditable = settings && settings.editable;

  const placeholderText = isIncludedInTheBasicService
    ? t('AdditionalService:IncludedInTheBasicService')
    : t('AdditionalService:CalculationOnRequest');

  if (!isCountEditable) {
    return (
      <RowContainer className={'MuiDataGrid-row'}>
        <div className={'cell'} style={{ width: props.renderedColumns[0].minWidth }}>
          <ServicesName {...props} />
        </div>
        <div className={'cell'} style={{ flex: 1 }}>
          {placeholderText}
        </div>
      </RowContainer>
    );
  }

  return (
    <RowContainer className={'MuiDataGrid-row'}>
      <div className={'cell'} style={{ width: props.renderedColumns[0].minWidth }}>
        <ServicesName {...props} />
      </div>
      <div className={'cell'} style={{ width: props.renderedColumns[1].minWidth }}>
        <CostField {...props} />
      </div>
      <div className={'cell'} style={{ flex: 1 }}>
        {placeholderText}
      </div>
    </RowContainer>
  );
}

export { Template5Placeholder };
