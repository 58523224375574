import { ServicesDirProps } from '@services/requests/servicesService/servicesDirectoriesService/interface';
import { ServicesProps } from '@services/requests/servicesService/servicesServicesService/interface';

import { getTemplateParams } from './components/AdditionalServicesTable/helpers';

export function getSortedServicesByOrder(services: ServicesProps[] | ServicesDirProps[]) {
  const servicesCopy = services.slice();
  const sortedServices = servicesCopy.sort(sortServicesByOrderCallback);

  const countOfDefaultOrder = sortedServices.filter((item) => {
    const { order } = getTemplateParams(item.template);
    return order === 0;
  }).length;

  const defaultOrderServices = sortedServices.slice(0, countOfDefaultOrder);
  const sortedByOrderServices = sortedServices.slice(countOfDefaultOrder);

  return [...sortedByOrderServices, ...defaultOrderServices];
}

export function sortServicesByOrderCallback(
  a: ServicesProps | ServicesDirProps,
  b: ServicesProps | ServicesDirProps
) {
  const { order: orderA } = getTemplateParams(a.template);
  const { order: orderB } = getTemplateParams(b.template);

  return orderA - orderB;
}
