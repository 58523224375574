import Stack from '@mui/material/Stack';
import ServicesSummaryItem from '@pages/Basket/components/ServicesList/components/OrderList/components/OrderListItem/components/ServicesSummary/components/ServicesSummaryItem';
import { OrderProduct } from '@services/requests/orderService/orderProductsLoader/interface';
import { ProductSummaryProps } from '@services/requests/orderService/types';
import React, { FC } from 'react';

import { getSortedOrderComplexServices } from './helpers';

const ComplexSummary: FC<ProductSummaryProps> = ({ product, currencyCode, langId }) => {
  const productData: OrderProduct[] = JSON.parse(product.data);
  const sortedSerives = getSortedOrderComplexServices(productData);

  return (
    <Stack spacing={'10px'}>
      {sortedSerives.length > 0 &&
        sortedSerives.map((p) => (
          <ServicesSummaryItem
            key={`${product.id}-${p.id}`}
            product={p}
            currencyCode={currencyCode}
            langId={langId}
            orderCurrency={currencyCode}
            products={productData}
          />
        ))}
    </Stack>
  );
};

export default ComplexSummary;
