import { GridRenderCellParams } from '@mui/x-data-grid/models/params/gridCellParams';
import useWidget from '@pages/AdditionalServices/context';
import FieldCellContainer from '@pages/AdditionalServices/tabs/AdditionalForm/components/AdditionalServiceContainer/components/AdditionalServiceIsVariantListing/components/AdditionalServicesTable/components/StyledComponents';
import { getFormattedPrice } from '@pages/AdditionalServices/tabs/CustomsPayments/helpers/price';
import React from 'react';

const SumField = (props: GridRenderCellParams<any, any, any>) => {
  const { row } = props;
  const { templateConfig } = row;
  const [basket] = useWidget().useBasketAdditionalService().useState();
  const [{ currencies }] = useWidget().useAdditionalInfo().useState();

  const styles = templateConfig?.find((config) => config.field === props.field)?.styles ?? '';

  const result = basket[row.id];

  const currentCurrency = result
    ? currencies.find((c) => Number(result.currencyId) === Number(c.id))?.code
    : '';

  return (
    <>
      <style>{` .sum-cell-${row.id} { ${styles} } `}</style>
      <FieldCellContainer className={`sum-cell-${row.id}`}>
        {getFormattedPrice(row.cost, 2)} {currentCurrency}
      </FieldCellContainer>
    </>
  );
};

export default SumField;
