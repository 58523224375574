import { useYaHit } from '@context/useYaHit';
import AdditionalForm from '@pages/AdditionalServices/tabs/AdditionalForm';
import CustomsPayments from '@pages/AdditionalServices/tabs/CustomsPayments';
import DeliveryForm from '@pages/AdditionalServices/tabs/DeliveryForm';
import { COUNT_STATIC_TABS } from '@pages/consts';
import React, { useEffect } from 'react';

import useWidget from './context';

interface TabPanelProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

const FormWidget = () => {
  const { useTabs, useTabsActive, actions } = useWidget();
  const { init } = actions;
  const [tabs] = useTabs().useState();
  const [activeTab] = useTabsActive().useState();

  useYaHit({});

  useEffect(() => {
    init();
  }, []);

  return (
    <div id={'my-global-portal-container'}>
      <CustomTabPanel value={activeTab} index={0}>
        <DeliveryForm />
      </CustomTabPanel>
      {/* <CustomTabPanel value={activeTab} index={1}>
        <CustomsPayments />
      </CustomTabPanel> */}
      {tabs.map((service, index) => (
        <CustomTabPanel key={index} value={activeTab} index={index + COUNT_STATIC_TABS}>
          <AdditionalForm service={service} />
        </CustomTabPanel>
      ))}
    </div>
  );
};

export default FormWidget;
