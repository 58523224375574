import {
  ServicesProps,
  ServicesServicePriceCalculationMutationProps,
} from '@services/requests/servicesService/servicesServicesService/interface';

import { TemplateParams } from '../interface';

export const getRowsForTemplate4 = (
  s: ServicesProps,
  templateParams: TemplateParams,
  serviceData?: ServicesServicePriceCalculationMutationProps
) => {
  const {
    additionalTemplateParams: { enabled, items },
    isSectionAdditionalServices,
    type: templateType,
    order,
  } = templateParams;

  const offerFormula = s.price_offers.map((offer) => offer.formula).join('|');
  const serviceFieldCodes = s.fields.map((field) => field.code);
  const dirFields = s.allFields.filter((field) => !serviceFieldCodes.includes(field.code));
  const filteredFieldsByFormulaUsage = dirFields
    .filter((field) => offerFormula.includes(field.code))
    .map((f) => f.code);

  let item = {
    id: s.id,
    description: s.description,
    localizedDescriptions: s.localized_descriptions_data || [],
    name: s.name,
    cost: 0,
    count: 1,
    sum: 0,
    course: 0,
    conversePayment: 0,
    sumConversePayment: 0,
    taxRate: 0,
    sumTax: 0,
    total: 0,
	template: templateType,
    calculationAccordingToApplication: true,
    isCalculationOnRequest: false,
    isIncludedInTheBasicService: false,
    fieldCodeInUse: filteredFieldsByFormulaUsage,
    dirFieldCodes: dirFields.map((f) => f.code),
    groupFieldCodesInUse: filteredFieldsByFormulaUsage,
	templateConfig: enabled ? items : [],
  };

  if (serviceData?.result?.result) {
    const {
      basePrice,
      fullPriceInTargetCurrency,
      taxInTargetCurrency,
      innerCurrency,
      conversionFee,
    } = serviceData.result.result;

    item = {
      ...item,
      course: +innerCurrency,
      cost: basePrice,
      total: fullPriceInTargetCurrency,
      conversePayment: conversionFee,
      sumConversePayment: basePrice + conversionFee,
      sumTax: taxInTargetCurrency,
      calculationAccordingToApplication: basePrice < 0,
      isCalculationOnRequest: basePrice === -1,
      isIncludedInTheBasicService: basePrice === 0,
    };
  }

  const temp = s.allFields
    // .filter((f) => f.type !== 'hidden')
    .reduce((acc, f) => {
      acc = {
        ...acc,
        [f.code]: f,
      };
      return acc;
    }, item);

  return [temp];
};
