import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { styled } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { GridRenderCellParams } from '@mui/x-data-grid/models/params/gridCellParams';
import useDelivery from '@pages/AdditionalServices/tabs/DeliveryForm/useDelivery';
import { getLocalization } from '@settings/services/helpers/getLocalization';
import React from 'react';

const ServiceNameContainer = styled('div')`
  width: 100%;
  overflow: hidden;
`;

const ServicesName = (props: GridRenderCellParams<any, any, any>) => {
  const { row } = props;
  const { description, localizedDescriptions, id, templateConfig } = row;

  const styles = templateConfig?.[0]?.styles ?? '';

  const splittedId = id.split('-');
  const paymentServiceIndex = +splittedId[splittedId.length - 1];

  const name = row.template === '3' ? `Платеж ${paymentServiceIndex + 1}` : row.name;

  const [settings] = useDelivery().useSettings().useState();
  const { primaryLanguageId } = settings;

  const serviceDescription = getLocalization(
    description,
    localizedDescriptions || [],
    primaryLanguageId
  );

  if (row.type === 'result') {
    return (
      <Tooltip title={'Итог'} style={{ width: '300px' }} placement={'top-end'}>
        <ServiceNameContainer>{'Итог'}</ServiceNameContainer>
      </Tooltip>
    );
  }

  return (
    <>
      <style>{` .name-cell-${row.id} { ${styles} } `}</style>
      <ServiceNameContainer className={`name-cell-${row.id}`} style={{ padding: '0px 10px' }}>
        {name}{' '}
        {serviceDescription && serviceDescription !== '' && (
          <Tooltip title={<div dangerouslySetInnerHTML={{ __html: serviceDescription }} />}>
            <IconButton sx={{ width: 16, height: 16, backgroundColor: 'grey.100' }}>
              <QuestionMarkIcon sx={{ fontSize: 8 }} />
            </IconButton>
          </Tooltip>
        )}
      </ServiceNameContainer>
    </>
  );
};

export default ServicesName;
