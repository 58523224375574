import { localizedMessagesService } from '@services/requests/localizedMessagesService';
import { LocalizedMessage } from '@services/requests/localizedMessagesService/interfaces';
import { servicesDirectoriesService } from '@services/requests/servicesService/servicesDirectoriesService';
import { ServicesDirProps } from '@services/requests/servicesService/servicesDirectoriesService/interface';
import { servicesServicesService } from '@services/requests/servicesService/servicesServicesService';
import { ServicesProps } from '@services/requests/servicesService/servicesServicesService/interface';

const getLocalizedByIds = async (ids: string[]): Promise<LocalizedMessage[]> => {
  return await localizedMessagesService().GetMessagesArray(ids);
};

export const getServiceRoots = async () => {
  const services = await servicesDirectoriesService().LoadRoots();
  const ids = services.map((s) => s.localized_names).flat();

  const messages = await getLocalizedByIds(ids);
  return services.map((s) => ({
    ...s,
    localized_names: s.localized_names.map((l) => messages.find((n) => n.id === l)),
  }));
};

export const getDirItems = async (_in: string[]): Promise<ServicesDirProps[]> => {
  const services = await servicesDirectoriesService().Load({
    where: { id: { _in }, is_active: { _equals: true } },
  });
  const ids = services.map((s) => s.localized_names).flat();
  const messages = await getLocalizedByIds(ids);

  return services.map((s) => ({
    ...s,
    childs: s.childs.filter((child) => child.is_active),
    localized_names_data: s.localized_names.map((l) => messages.find((n) => n.id === l)),
  }));
};

export const getAddServiceItems = async (_equals: string): Promise<ServicesProps[]> => {
  const services = await servicesServicesService().Load({
    where: { parent: { _equals }, is_active: { _equals: true } },
  });
  const ids = services.map((s) => [...s.localized_names, ...s.localized_descriptions]).flat();
  const messages = await getLocalizedByIds(ids);

  return services.map((s) => ({
    ...s,
    localized_names_data: s.localized_names.map((l) => messages.find((n) => n.id === l)),
    localized_descriptions_data: s.localized_names.map((l) => messages.find((n) => n.id === l)),
  }));
};
