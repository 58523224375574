import { TaxNameProps } from '@helpers/getTaxNames';
import { formatPrice } from '@pages/OfferDetail/helpers';
import { LocalizedMessage } from '@services/requests/localizedMessagesService/interfaces';
// import { ServicesServicePriceCalculationMutationProps } from '@services/requests/servicesService/servicesServicesService/interface';

interface Props {
  service: any[]; // TODO: rollback after backend fix
  langId: string;
  targetCurrency: string;
  currencyService: string;
  amount: number;
  taxNames: TaxNameProps[];
}

const getAdditionalServiceTableRowsForDetail = ({
  service,
  langId,
  targetCurrency,
  currencyService,
  amount,
  taxNames,
}: Props) => {
  return service.map((s) => {
    const serviceName =
      s.service.localized_names_data?.find((l: LocalizedMessage) => l.lang_id === langId)
        ?.message || s.service.name;

    return {
      id: s.service_id,
      name: serviceName,
      cost: `${formatPrice(s.price_offer_result.result.basePrice)} ${currencyService}`,
      count: amount,
      unit: '-',
      sum: `${formatPrice(s.price_offer_result.result.priceInTargetCurrency)} ${targetCurrency}`,
      tax: {
        value: `${formatPrice(s.price_offer_result.result.taxInTargetCurrency)} ${targetCurrency}`,
        vat: taxNames.find((t) => String(t.taxId) === String(s.price_offer_result.data.tax_id))
          .localizedName,
      },
      total: `${formatPrice(
        s.price_offer_result.result.fullPriceInTargetCurrency
      )} ${targetCurrency}`,
    };
  });
};

export default getAdditionalServiceTableRowsForDetail;
