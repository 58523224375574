import breakpoints from '@assets/theme/breakpoints';
import { styled } from '@mui/material/styles';

const ServicesSummaryItemStyledComponent = styled('div', {
  name: 'ServicesSummaryItemStyledComponent',
})`
  display: grid;
  gap: 16px;

  .services-summary-item {
    &__list {
      display: grid;
      gap: 10px;

      &.child {
        padding-left: 20px;
      }
    }

    &__item {
      display: flex;
      justify-content: space-between;
      width: 100%;
      gap: 5px;
      align-items: center;
      color: ${({ theme }) => theme.palette.common.black};

      @media (max-width: ${breakpoints.values.xsm}px) {
        line-height: 1.3;
        display: grid;
        grid-template-columns: 1fr;
        gap: 17px;
      }

      .price {
        @media (max-width: ${breakpoints.values.xsm}px) {
          margin-top: 3px;
        }
      }
    }
  }
`;

export default ServicesSummaryItemStyledComponent;
