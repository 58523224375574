import { getFilesIds } from '@components/pages/Products/components/RouteProduct/useGetFilesFromRoute';
import useBaseDataContext from '@context/useBaseDataContext';
import { getLocalizationById, parseUrl } from '@context/useMyRouting/helpers';
import { LinearProgress } from '@material-ui/core';
import Button from '@mui/material/Button';
import { useCustomsPayments } from '@pages/AdditionalServices/tabs/CustomsPayments/components/CustomsPaymentsForm/context';
import useBasket from '@pages/Basket/useBasket';
import OfferDetail from '@pages/OfferDetail';
import { PDFExport } from '@progress/kendo-react-pdf';
import { filesService } from '@services/requests/filesService';
import { FileData } from '@services/requests/filesService/interface';
import { orderService } from '@services/requests/orderService';
import { Order } from '@services/requests/orderService/interface';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import getTaxNames, { TaxNameProps } from '../../helpers/getTaxNames';
import Container from './StyledComponents';

const loadPreOrder = async (id: string) => {
  return await orderService('').Load('preOrder', id);
};

const PreOrderPage = () => {
  const { useBaseCustomsPaymentData } = useCustomsPayments();
  const [{ countries, currencies }] = useBaseCustomsPaymentData().useState();
  const { useBaseData } = useBaseDataContext();
  const [order, setOrder] = useState<Order | undefined>(undefined);
  const [needPrint, setNeedPrint] = useState<boolean>(false);
  const pdfExportComponent = React.useRef(null);
  const button = React.useRef(null);
  const [isSaved, setIsSaved] = useState<boolean>(false);
  const [files, setFiles] = useState<FileData[]>([]);
  const [taxNames, setTaxNames] = useState<TaxNameProps[]>([]);
  const [baseData] = useBaseData().useState();
  const langCode = getLocalizationById(order?.language_id)?.countryCode;
  const { useOrder } = useBasket();
  const setBasketOrder = useOrder().setState;
  const { t } = useTranslation('OfferContainers', { lng: langCode });

  const exportPDFWithComponent = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
      setIsSaved(true);
    }
  };

  useEffect(() => {
    if (!needPrint) {
      return;
    }

    let interval;

    if (!isSaved) {
      interval = setInterval(() => {
        exportPDFWithComponent();
      }, 6000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [isSaved, needPrint]);

  useLayoutEffect(() => {
    if (!baseData) { // TODO: Add ALTA currency and country check
      return;
    }
    const { id = '', needPrint = '0' } = parseUrl(window.location.href);

    setNeedPrint(needPrint === '1');
    loadPreOrder(id).then((order) => {
      const filesToLoad = getFilesIds(order.products, baseData);
      filesService().LoadFilesById(filesToLoad).then(setFiles);
      getTaxNames('1').then(setTaxNames);
      setOrder(order);
      setBasketOrder(order);
    });
  }, [baseData]);

  if (!baseData || order === undefined) {
    return (
      <Container>
        <LinearProgress />
      </Container>
    );
  }

  return (
    <PDFExport
      ref={pdfExportComponent}
      paperSize="A4"
      margin={{ bottom: 40, top: 20 }}
      scale={0.4}
      fileName={`Report for ${new Date().getFullYear()}`}
    >
      <Container>
        <Button style={{ display: 'none' }} ref={button} onClick={exportPDFWithComponent}>
          Print
        </Button>
        <OfferDetail
          files={files}
          taxNames={taxNames}
          order={order}
          baseData={baseData}
          title={t('OfferContainers:OfferDetailTitle')}
        />
      </Container>
    </PDFExport>
  );
};

export default PreOrderPage;
