import { TextFieldPropsSizeOverrides } from '@mui/material/TextField/TextField';
import { OverridableStringUnion } from '@mui/types';
import { ContractorsOptions, FieldState } from '@pages/AdditionalServices/context/types';
import { CurrencyData } from '@services/requests/searchLoaders/currencyLoader/CurrencyLoaderQuery';
import { ServicesFieldProps } from '@services/requests/servicesService/interface';
import { TaxData } from '@services/requests/taxLoader/TaxLoaderQuery';
import { OptionSelect } from '@services/types';
import { SetterContext } from '@settings/core/stateContexts/types';
import React from 'react';

import HandBookField from './HandBookField';
import LocationField from './LocationField';
import NumberField from './NumberField';
import SimpleHandbookField from './SimpleHandbookField';
import TargetHandbookField from './TargetHandbookField';

export interface PropsGetField {
  isSyncWithStates?: boolean;
  field: ServicesFieldProps;
  options?: OptionSelect[];
  size?: OverridableStringUnion<'small' | 'medium', TextFieldPropsSizeOverrides>;
  withLabel?: boolean;
  contractorsOptions: ContractorsOptions;
  taxes: TaxData[];
  currencies: CurrencyData[];
  state: FieldState;
  realTimeValidation?: boolean;
  setState(value: SetterContext<FieldState>): void;
}

const GetField = (props: PropsGetField) => {
  const { contractorsOptions, taxes, currencies } = props;

  switch (props.field.type) {
    case 'hidden':
    case 'switch':
    case 'number':
      return <NumberField {...props} />;
    case 'handbook':
      return <HandBookField {...props} />;
    case 'location':
      return <LocationField {...props} />;
    case 'tax':
      const taxesOptions = taxes.map((t) => ({
        value: t.id,
        label: t.default_name,
      }));
      return <TargetHandbookField {...props} options={taxesOptions} />;
    case 'currency':
      const currenciesOptions = currencies.map((t) => ({
        value: t.id,
        label: t.default_name,
      }));
      return <TargetHandbookField {...props} options={currenciesOptions} />;
    case 'certification_contractor':
    case 'consulting_contractor':
    case 'customs_contractor':
    case 'inspection_contractor':
    case 'insurance_contractor':
      const [type] = props.field.type!.split('_');

      return <SimpleHandbookField {...props} options={contractorsOptions[type]} />;
    default:
      return null;
  }
};

export default GetField;
