import { GridRenderCellParams } from '@mui/x-data-grid/models/params/gridCellParams';
import FieldCellContainer from '@pages/AdditionalServices/tabs/AdditionalForm/components/AdditionalServiceContainer/components/AdditionalServiceIsVariantListing/components/AdditionalServicesTable/components/StyledComponents';
import React from 'react';

const CourseField = (props: GridRenderCellParams<any, any, any>) => {
  const { row } = props;
  const { templateConfig } = row;

  const styles = templateConfig?.find((config) => config.field === props.field)?.styles ?? '';

  return (
    <>
      <style>{` .course-cell-${row.id} { ${styles} } `}</style>
      <FieldCellContainer className={`course-cell-${row.id}`}>{row.course}</FieldCellContainer>
    </>
  );
};

export default CourseField;
