import { FieldState, FieldStates } from '@pages/AdditionalServices/context/types';
import { contractorLoader } from '@services/requests/searchLoaders/contractorLoader';
import { ServicesFieldProps } from '@services/requests/servicesService/interface';

export const loadContractors = async () => {
  const consulting = await contractorLoader()
    .LoadDivisionContractors('consulting')
    .then((r) =>
      r.map((i) => ({
        value: i.id,
        label: i.default_name,
      }))
    );
  const inspection = await contractorLoader()
    .LoadDivisionContractors('inspection')
    .then((r) =>
      r.map((i) => ({
        value: i.id,
        label: i.default_name,
      }))
    );
  const certification = await contractorLoader()
    .LoadDivisionContractors('certification')
    .then((r) =>
      r.map((i) => ({
        value: i.id,
        label: i.default_name,
      }))
    );
  const customs = await contractorLoader()
    .LoadDivisionContractors('customs')
    .then((r) =>
      r.map((i) => ({
        value: i.id,
        label: i.default_name,
      }))
    );
  const insurance = await contractorLoader()
    .LoadDivisionContractors('insurance')
    .then((r) =>
      r.map((i) => ({
        value: i.id,
        label: i.default_name,
      }))
    );

  return {
    certification,
    inspection,
    insurance,
    consulting,
    customs,
  };
};

export function groupFields(data: FieldStates, field: ServicesFieldProps[]) {
  return Object.keys(data).reduce((acc, key) => {
    const fields = data[key];

    Object.keys(fields).forEach((fieldKey) => {
      const fieldValue = fields[fieldKey]?.value;
      const handBookValue = fields[fieldKey].handBookValue;

      if (!acc[fieldKey]) {
        acc[fieldKey] = { values: [], field: '' };
      }

      // Добавляем значение в массив, если оно еще не было добавлено
      const valueToAdd = fieldValue !== null ? fieldValue : handBookValue;

      if (!acc[fieldKey].values.includes(Number(valueToAdd))) {
        acc[fieldKey].values.push(Number(valueToAdd));
      }

      // Определяем тип значения
      acc[fieldKey].typeComponent = fieldValue !== null ? 'number' : 'handbook';
      acc[fieldKey].field = field.find((i) => i.code === fieldKey);
    });

    return acc;
  }, {});
}

export const getStateFields = (fields: ServicesFieldProps[], formState: FieldState) => {
  return fields.reduce((acc: FieldState, field) => {
    const hasFieldInState = formState[field.code];

    if (hasFieldInState) {
      acc = {
        ...acc,
        [field.code]: {
          value: hasFieldInState.value,
          handBookValue: Number(hasFieldInState.handBookValue) || null,
        },
      };
    } else {
      acc = {
        ...acc,
        [field.code]: {
          value: field.value,
          handBookValue: Number(field.handbook_limitations[0]) || null,
        },
      };
    }

    return acc;
  }, {});
};

export const getServiceId = (key: string, isStrictCheck: boolean) => {
  if (isStrictCheck && !key.includes('payment') && !key.includes('sub-payment')) {
    return null;
  }
  const splittedKey = key.split('.');
  return +splittedKey[0];
};
