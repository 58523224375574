import { DEFAULT_TEMPLATE_PARAMS } from './constants';
import { TemplateParams } from './interface';

export function getTemplateParams(template: string): TemplateParams {
  const parsedTemplate = JSON.parse(template);

  if (typeof parsedTemplate === 'number') {
    return {
      ...DEFAULT_TEMPLATE_PARAMS,
      type: parsedTemplate.toString(),
    };
  }

  return { ...DEFAULT_TEMPLATE_PARAMS, ...parsedTemplate };
}
