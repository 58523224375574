import { GridColDef } from '@mui/x-data-grid';

import ServicesName from '../../ServicesName';
import CostField from './CostField';
import SumField from './SumField';
import TaxField from './TaxField';
import TotalField from './TotalField';
import UnitField from './UnitField';

export const getTemplate5Columns = (fieldColumns: GridColDef[]) => {
  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: '',
      headerAlign: 'center',
      minWidth: 200,
      sortable: false,
      renderCell: ServicesName,
    },
    {
      field: 'cost-param',
      headerName: 'Стоимость',
      headerAlign: 'center',
      minWidth: 220,
      sortable: false,
      renderCell: CostField,
    },
    {
      field: 'count',
      headerName: 'Кол-во',
      headerAlign: 'center',
      minWidth: 100,
      sortable: false,
    },
    {
      field: 'unit',
      headerName: 'Единица измерения',
      headerAlign: 'center',
      minWidth: 300,
      flex: 1,
      sortable: false,
      renderCell: UnitField,
    },
    {
      field: 'cost',
      headerName: 'Сумма',
      headerAlign: 'center',
      width: 120,
      flex: 1,
      sortable: false,
      renderCell: SumField,
    },
    {
      field: 'tax',
      headerName: 'Налог',
      headerAlign: 'center',
      minWidth: 120,
      flex: 1,
      sortable: false,
      renderCell: TaxField,
    },
    {
      field: 'total',
      headerName: 'Итого',
      headerAlign: 'center',
      flex: 1,
      minWidth: 120,
      sortable: false,
      renderCell: TotalField,
    },
  ];

  return columns;
};
