import { GridColDef } from '@mui/x-data-grid';
import { ServicesFieldProps } from '@services/requests/servicesService/interface';
import { ServicesProps } from '@services/requests/servicesService/servicesServicesService/interface';

import CountField from './Templates/Template4/CountField';

export function getGridColFormattedFields(fields: ServicesFieldProps[]): GridColDef[] {
  return fields.map((f) => {
    return {
      field: f.code,
      headerName: `Кол-во ${f.name}`,
      headerAlign: 'center',
      minWidth: 250,
      sortable: false,
      renderCell: CountField,
    };
  });
}

export function getSortedRowsByOrder(data: any) {
  if (!data.length) {
    return data;
  }
  const { rows, columns } = data[0];
  const rowsCopy = rows.slice();
  const sortedRows = rowsCopy.sort((a, b) => a.order - b.order);

  const countOfDefaultOrder = sortedRows.filter((item) => {
    return item.order === 0;
  }).length;

  const defaultOrderRows = sortedRows.slice(0, countOfDefaultOrder);
  const sortedByOrderRows = sortedRows.slice(countOfDefaultOrder);

  const mergedRows = [...sortedByOrderRows, ...defaultOrderRows];

  return [
    {
      columns,
      rows: mergedRows,
    },
  ];
}

export function getTemplate4ColumnFields(
  service: ServicesProps,
  isGroup: boolean,
  isSectionAdditionalServices: boolean
) {
  const offerFormula = service.price_offers.map((offer) => offer.formula).join('|');
  const localServiceFieldCodes = service.fields.map((field) => field.code);
  const onlyDirFields = service.allFields.filter(
    (field) => !localServiceFieldCodes.includes(field.code) && field.type !== 'hidden'
  );
  const filteredDirFieldsByFormulaUsage = onlyDirFields.filter((field) =>
    offerFormula.includes(field.code)
  );
  const filteredAllFieldsByUsage = service.allFields
    .filter((field) => offerFormula.includes(field.code))
    .filter((field) => {
      const settings = field.hasOwnProperty('settings') ? JSON.parse(field.settings) : false;

      return settings && settings.editable;
    });
  const formattedDirFilteredFieldsInUse = getGridColFormattedFields(
    filteredDirFieldsByFormulaUsage
  );
  const formattedDirFields = getGridColFormattedFields(onlyDirFields);
  const formattedAllFieldsInUse = getGridColFormattedFields(filteredAllFieldsByUsage);

  let templateColumns = formattedAllFieldsInUse;

  if (isGroup && !isSectionAdditionalServices) {
    templateColumns = formattedDirFields;
  } else if (!isGroup && !isSectionAdditionalServices) {
    templateColumns = formattedDirFilteredFieldsInUse;
  }

  return templateColumns;
}