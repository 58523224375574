import { ServicesProps } from '../../../../../../../../../../../services/requests/servicesService/servicesServicesService/interface';
import { BasketAdditionalService, PaymentCount } from '../../../../../../../../../context/types';
import { getColumnsForAdditionalService } from './DataForTableGenerator';
import { getHeaderRowForTemplate3 } from './HeaderRowGeneratorTemplate3';
import { getResultRowForTemplate3 } from './ResultRowGeneratorTemplate3';
import { getRowsForTemplate3 } from './RowGeneratorTemplate3';

export const getChildrenGridDataTemplate3 = (
  id: string,
  service: ServicesProps,
  basket: BasketAdditionalService,
  template: string,
  paymentsCount: PaymentCount
) => {
  const count = paymentsCount?.[id] ?? 1;

  const filteredBasketServices = Object.entries(basket)
    .filter(([key]) => key.includes(id) && key.includes('payment') && !key.includes('sub-payment'))
    .map(([_, s]) => s)
    .sort((a, b) => (a?.service.id > b?.service.id ? 1 : -1));

  const columns = getColumnsForAdditionalService(service, template, [], []); // Получаем колонки для текущего элемента
  const initRows = getRowsForTemplate3(service, count, null, basket[id]) as any[]; // Получаем строки для текущего элемента
  const calculatedRows = filteredBasketServices
    .map((s) =>
      getRowsForTemplate3(s.service, count, filteredBasketServices.length, basket[s.service.id])
    )
    .flat();

  const headerRow = getHeaderRowForTemplate3();

  initRows.unshift(headerRow);

  const rows = initRows.slice();

  const filteredCalculatedRows = calculatedRows.filter((row) => {
    return row.childServiceIdsToUpdate[0].includes(row.id);
  });
  const sortedCalculatedRows = filteredCalculatedRows
    .slice()
    .sort((a, b) => (a.id > b.id ? 1 : -1));

  if (filteredBasketServices.length === rows.length - 1) {
    rows.splice(1, filteredBasketServices.length, ...sortedCalculatedRows);
  }

  const resultRow = getResultRowForTemplate3(rows.slice(1));
  rows.push(resultRow);

  return [{ rows, columns }];
};
