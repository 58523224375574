import ServiceData from '@components/pages/Products/components/ServiceSectionDetail/ServiceData';
import ServiceFileList from '@components/pages/Products/components/ServiceSectionDetail/ServiceFileList';
import ServiceSummaryComp from '@components/pages/Products/components/ServiceSummary';
import useMyRouting from '@context/useMyRouting/useMyRouting';
import Typography from '@mui/material/Typography';
import getSummaryForServices from '@pages/AdditionalServices/containers/methods/basket/getSummaryForServices';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ServiceSectionDetailProps } from './types';

const ServiceSectionDetail: FC<ServiceSectionDetailProps> = (props) => {
  const {
    section: { services, sectionName },
    order,
  } = props;
  const { t } = useTranslation('Common');
  const [settings] = useMyRouting().useSettings().useState();

  // Выводим наименование корневой директории услуг в качестве заголовка секции услуг
  const serviceSectionName =
    sectionName.localized_names.find((l) => l.lang_id === settings.primaryLanguageId)?.message ||
    sectionName.name;

  return (
    <>
      <Typography variant={'h2'} color={'common.black'} className={'section-title'}>
        {serviceSectionName}
      </Typography>
      {services.map((service) => (
        <ServiceData key={service.id} langID={settings.primaryLanguageId} product={service} />
      ))}
      <ServiceSummaryComp
        title={`${t('Common:Total')} ${serviceSectionName}`}
        summary={getSummaryForServices(services, true)}
        order={order}
      />
      <ServiceFileList products={services} />
    </>
  );
};

export default ServiceSectionDetail;
