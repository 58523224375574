import { MButton } from '@assets/mui/@material-extend';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useWidget from '@pages/AdditionalServices/context';
import AdditionalServicesTable from '@pages/AdditionalServices/tabs/AdditionalForm/components/AdditionalServiceContainer/components/AdditionalServiceIsVariantListing/components/AdditionalServicesTable';
import { ServicesProps } from '@services/requests/servicesService/servicesServicesService/interface';
import React, { useMemo, useState } from 'react';

import { getTemplateParams } from './components/AdditionalServicesTable/helpers';
import { getSortedServicesByOrder } from './helpers';
import AdditionalServiceListingContainer from './StyledComponents';

const AdditionalServiceIsVariantListing = () => {
  const { useAdditionalServices, useAdditionalInfo } = useWidget();
  const [{ currencies, taxes, contractors }] = useAdditionalInfo().useState();
  const [services] = useAdditionalServices().useState();

  const [isExpanded, setIsExpanded] = useState(false);

  const [mainServices, additionalServices] = useMemo(() => {
    const [main, additional] = services.reduce(
      (prev, s) => {
        const [main, additional] = prev;
        const { isSectionAdditionalServices } = getTemplateParams(s.template);

        if (isSectionAdditionalServices) {
          return [main, [...additional, s]];
        }
        return [[...main, s], additional];
      },
      [[], []]
    );

    const sortedMainServices = getSortedServicesByOrder(main);
    const sortedAdditionalServices = getSortedServicesByOrder(additional);

    return [sortedMainServices, sortedAdditionalServices] as [ServicesProps[], ServicesProps[]];
  }, [services]);

  const toggleExpandHandler = () => {
    setIsExpanded((prevState) => !prevState);
  };

  const ExpandIcon = isExpanded ? ExpandLessIcon : ExpandMoreIcon;
  const isAdditionalServicesExist = additionalServices.length > 0;

  return (
    <AdditionalServiceListingContainer>
      <div style={{ display: 'grid', gap: 30, gridTemplateColumns: '100%' }}>
        {mainServices.map((service) => {
          const { id } = service;

          return (
            <AdditionalServicesTable
              key={id}
              taxes={taxes}
              currencies={currencies}
              contractorsOptions={contractors}
              service={service}
              services={services}
            />
          );
        })}

        {isAdditionalServicesExist && (
          <>
            <MButton
              className={'expand-additional-service'}
              variant={'outlined'}
              color={'secondary'}
              fullWidth={true}
              startIcon={<ExpandIcon fontSize="medium" sx={{ color: '#3460B4' }} />}
              onClick={toggleExpandHandler}
            >
              {isExpanded ? 'Скрыть' : 'Показать'} дополнительные услуги
            </MButton>
            {isExpanded &&
              additionalServices.map((service) => {
                const { id } = service;

                return (
                  <AdditionalServicesTable
                    key={id}
                    taxes={taxes}
                    currencies={currencies}
                    contractorsOptions={contractors}
                    service={service}
                    services={services}
                  />
                );
              })}
          </>
        )}
      </div>
    </AdditionalServiceListingContainer>
  );
};

export default AdditionalServiceIsVariantListing;
