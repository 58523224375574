import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { GridRenderCellParams } from '@mui/x-data-grid/models/params/gridCellParams';
import useWidget from '@pages/AdditionalServices/context';
import { FieldState } from '@pages/AdditionalServices/context/types';
import FieldCellContainer from '@pages/AdditionalServices/tabs/AdditionalForm/components/AdditionalServiceContainer/components/AdditionalServiceIsVariantListing/components/AdditionalServicesTable/components/StyledComponents';
import { getFormattedPrice } from '@pages/AdditionalServices/tabs/CustomsPayments/helpers/price';
import { SetterContext, StateUpdater } from '@settings/core/stateContexts/types';
import React, { CSSProperties, useState } from 'react';

const style: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  justifyContent: 'center',
  textAlign: 'center',
};

const CostField = (props: GridRenderCellParams<any, any, any>) => {
  const { row } = props;
  const { templateConfig } = row;

  const styles = templateConfig?.[1]?.styles ?? '';

  const {
    useFieldStateServices,
    useBasketAdditionalService,
    useAdditionalInfo,
    actions: { recalculateService },
  } = useWidget();

  const [basket] = useBasketAdditionalService().useState();
  const [{ currencies }] = useAdditionalInfo().useState();
  const [states, setStates] = useFieldStateServices().useState();

  const result = basket[row.id];

  const paymentValue = (
    Object.values(row).filter((value) => typeof value === 'object') as any[]
  ).find((prop) => prop.code?.includes('paymentvalueTemp'));

  const [value, setValue] = useState<string>(paymentValue?.value.toString());

  const currentCurrency = result
    ? currencies.find((c) => Number(result.currencyId) === Number(c.id))
    : '';

  const settings =
    paymentValue && paymentValue.hasOwnProperty('settings')
      ? JSON.parse(paymentValue.settings)
      : false;

  const helper = paymentValue && paymentValue.hasOwnProperty('helper') && paymentValue.helper;

  const setState = (value: SetterContext<FieldState>) => {
    if (typeof value !== 'function') {
      setStates((prevState) => ({
        ...prevState,
        [row.id]: value,
      }));

      recalculateService(String(row.id));
      return;
    }

    const stateUpdater = value as StateUpdater<FieldState>;
    setStates((prevState) => {
      return {
        ...prevState,
        [row.id]: stateUpdater(states[row.id]),
      };
    });

    recalculateService(String(row.id));
  };

  const onChangeCost = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;

    if (!paymentValue) {
      return;
    }

    if (value === '0' || value === '') {
      setValue('0');
      setState((prevState: FieldState) => ({
        ...prevState,
        [paymentValue.code]: {
          ...prevState[paymentValue.code],
          value: '0',
        },
      }));
      return;
    }

    setValue(value);
    setState((prevState: FieldState) => {
      return {
        ...prevState,
        [paymentValue.code]: {
          ...prevState[paymentValue.code],
          value: value,
        },
      };
    });
  };

  const FieldTooltip = () =>
    helper && (
      <Tooltip title={<div dangerouslySetInnerHTML={{ __html: helper }} />}>
        <IconButton sx={{ width: 16, height: 16, backgroundColor: 'grey.100' }}>
          <QuestionMarkIcon sx={{ fontSize: 8 }} />
        </IconButton>
      </Tooltip>
    );

  if (settings && settings.editable) {
    return (
      <>
        <style>{` .cost-cell-${row.id} { ${styles} } `}</style>
        <div className={`cost-cell-${row.id}`} style={style}>
          <TextField
            fullWidth
            size={'small'}
            InputLabelProps={{ shrink: true }}
            type={'number'}
            label={''}
            variant="outlined"
            value={value}
            onChange={onChangeCost}
            sx={{ '.MuiInputBase-input': { textAlign: 'center' } }}
            InputProps={{
              endAdornment: <FieldTooltip />,
            }}
          />
        </div>
      </>
    );
  }

  return (
    <>
      <style>{` .cost-cell-${row.id} { ${styles} } `}</style>
      <FieldCellContainer className={`cost-cell-${row.id}`}>
        {getFormattedPrice(+value, 2)}
        {currentCurrency && ` ${currentCurrency.code}`} <FieldTooltip />
      </FieldCellContainer>
    </>
  );
};

export default CostField;
