import { BasketAdditionalService } from '@pages/AdditionalServices/context/types';
import { ServicesProps } from '@services/requests/servicesService/servicesServicesService/interface';

import { TemplateParams } from '../interface';
import { getColumnsForAdditionalService } from './DataForTableGenerator';
import { getRowsForTemplate4 } from './RowGeneratorTemplate4';

export const getChildrenGridDataTemplate4 = (
  id: string,
  service: ServicesProps,
  basket: BasketAdditionalService,
  templateParams: TemplateParams
) => {
  const {
    additionalTemplateParams: { enabled, items },
    type: templateType,
  } = templateParams;
  const templateConfig = enabled ? items : [];

  const rows = getRowsForTemplate4(service, templateParams, basket[id]);
  const columns = getColumnsForAdditionalService(service, templateType, [], templateConfig);

  return [{ rows, columns }];
};
