import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import Autocomplete from '@mui/material/Autocomplete';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { FieldState } from '@pages/AdditionalServices/context/types';
import { OptionSelect } from '@services/types';
import React from 'react';

import { PropsGetField } from './GetFields';

const getOptionLabel = (o: OptionSelect) => o.label;

const SimpleHandbookField = (props: PropsGetField) => {
  const {
    field,
    setState,
    state,
    options,
    size = 'medium',
    withLabel = true,
    realTimeValidation = false,
  } = props;

  const onChange = (_: any, option: OptionSelect) => {
    const value = option?.value || null;

    if (!value) {
      setState((prevState: FieldState) => ({
        ...Object.keys(prevState).reduce((acc, i) => {
          if (i === field.code) {
          } else {
            acc = { ...acc, [i]: prevState[i] };
          }

          return acc;
        }, {}),
      }));
      return;
    }

    setState((prevState) => ({
      ...prevState,
      [field.code]: {
        ...prevState[field.code],
        value: null,
        handBookValue: option?.value || null,
      },
    }));
  };

  const contractorsOptions = field.handbook_limitations?.reduce(
    (acc: OptionSelect[], id: string) => {
      const currentOption = options.find((o) => o.value === id);
      if (currentOption) {
        acc.push(currentOption);
      }

      return acc;
    },
    []
  );

  const value =
    contractorsOptions?.find(
      (i: OptionSelect) => Number(i.value) === Number(state[field.code]?.handBookValue)
    ) || null;
  const helper = field && field.hasOwnProperty('helper') && field.helper;

  return (
    <Autocomplete
      fullWidth
      value={value}
      renderInput={(params) => (
        <TextField
          {...params}
          error={realTimeValidation ? !!!value : false}
          size={size}
          label={withLabel ? `Name: ${field.name}` : ''}
          InputProps={{
            ...params.InputProps,
            startAdornment: helper && (
              <Tooltip title={<div dangerouslySetInnerHTML={{ __html: helper }} />}>
                <IconButton sx={{ width: 16, height: 16, backgroundColor: 'grey.100' }}>
                  <QuestionMarkIcon sx={{ fontSize: 12 }} />
                </IconButton>
              </Tooltip>
            ),
          }}
        />
      )}
      onChange={onChange}
      getOptionLabel={getOptionLabel}
      options={contractorsOptions || []}
    />
  );
};

export default SimpleHandbookField;
