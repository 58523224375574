import { GridColDef } from '@mui/x-data-grid';

import ServicesName from '../../ServicesName';
import AgentServicesField from './AgentServicesField';
import CostField from './CostField';
import CoursePurchaseField from './CoursePurchaseField';
import CurrencyTransferField from './CurrencyTransferField';
import ExcangeControlField from './ExcangeControlField';
import TaxAmountField from './TaxAmountField';
import TaxField from './TaxField';
import TotalField from './TotalField';
import TotalNoTaxField from './TotalNoTaxField';

export const getTemplate3Columns = (fieldColumns: GridColDef[]) => {
  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: '',
      headerAlign: 'center',
      minWidth: 200,
      sortable: false,
      renderCell: ServicesName,
    },
    {
      field: 'count',
      headerName: 'Сумма',
      headerAlign: 'center',
      minWidth: 100,
      sortable: false,
      renderCell: CostField,
    },
    {
      field: 'course',
      headerName: 'Курс покупки валюты',
      headerAlign: 'center',
      minWidth: 100,
      flex: 1,
      align: 'center',
      sortable: false,
    },
    {
      field: 'coursePurchase',
      headerName: 'Покупка валюты',
      headerAlign: 'center',
      minWidth: 120,
      flex: 1,
      sortable: false,
      renderCell: CoursePurchaseField,
    },
    {
      field: 'currencyTransfer',
      headerName: 'Валютный перевод',
      headerAlign: 'center',
      minWidth: 120,
      flex: 1,
      sortable: false,
      renderCell: CurrencyTransferField,
    },
    {
      field: 'exchangeControl',
      headerName: 'Валютный контроль',
      headerAlign: 'center',
      minWidth: 120,
      flex: 1,
      sortable: false,
      renderCell: ExcangeControlField,
    },
    {
      field: 'agentServices',
      headerName: 'Услуги агента',
      headerAlign: 'center',
      minWidth: 120,
      flex: 1,
      sortable: false,
      renderCell: AgentServicesField,
    },
    {
      field: 'totalNoTax',
      headerName: 'Итого без налога',
      headerAlign: 'center',
      minWidth: 120,
      flex: 1,
      sortable: false,
      renderCell: TotalNoTaxField,
    },
    {
      field: 'taxRate',
      headerName: 'Ставка налога',
      headerAlign: 'center',
      minWidth: 120,
      flex: 1,
      sortable: false,
      renderCell: TaxField,
    },
    {
      field: 'sumTax',
      headerName: 'Сумма налога',
      headerAlign: 'center',
      flex: 1,
      minWidth: 120,
      sortable: false,
      renderCell: TaxAmountField,
    },
    {
      field: 'total',
      headerName: 'Итог',
      headerAlign: 'center',
      flex: 1,
      minWidth: 120,
      sortable: false,
      renderCell: TotalField,
    },
  ];

  return columns;
};
