import useWidget from '@pages/AdditionalServices/context';

export const getResultRowForTemplate3 = (rows: any[]) => {
  const { useBasketAdditionalService, useAdditionalInfo } = useWidget();
  const [{ taxes }] = useAdditionalInfo().useState();
  const [basket] = useBasketAdditionalService().useState();

  const cost = rows.reduce((prev, row) => {
    const paymentFieldKey = Object.keys(row).find((prop) => prop.includes('paymentValueTemp3'));
    const paymentValue = row[paymentFieldKey]?.value || 0;
    return prev + paymentValue;
  }, 0);

  const [coursePurchase, coursePurchaseCurrency] = rows.reduce(
    (prev, row) => {
      const result =
        Object.entries(basket).find(
          ([key]) => key.includes(row.id) && key.includes(row.coursePurchaseField)
        )?.[1]?.result?.result?.basePrice ?? 0;
      const currency = basket[row.coursePurchaseField]?.currencyId || '';
      return [prev[0] + result, currency];
    },
    [0, 0]
  );

  const [currencyTransfer, currencyTransferCurrency] = rows.reduce(
    (prev, row) => {
      const result =
        Object.entries(basket).find(
          ([key]) => key.includes(row.id) && key.includes(row.currencyTransferField)
        )?.[1]?.result?.result?.basePrice ?? 0;
      const currency = basket[row.currencyTransferField]?.currencyId || '';
      return [prev[0] + result, currency];
    },
    [0, 0]
  );

  const [exchangeControl, exchangeControlCurrency] = rows.reduce(
    (prev, row) => {
      const result =
        Object.entries(basket).find(
          ([key]) => key.includes(row.id) && key.includes(row.exchangeControlField)
        )?.[1]?.result?.result?.basePrice ?? 0;
      const currency = basket[row.exchangeControlField]?.currencyId || '';
      return [prev[0] + result, currency];
    },
    [0, 0]
  );

  const [agentServices, agentServicesCurrency] = rows.reduce(
    (_, row) => {
      const result =
        Object.entries(basket).find(
          ([key]) => key.includes(row.id) && key.includes(row.agentServicesField)
        )?.[1]?.result?.result?.basePrice ?? 0;
      const currency = basket[row.agentServicesField]?.currencyId || '';
      return [result, currency];
    },
    [0, 0]
  );

  const [totalNoTax, totalNoTaxCurrency] = rows.reduce(
    (prev, row) => {
      const currency = basket[row.id]?.currencyId || '';
      return [prev[0] + row.cost, currency];
    },
    [0, 0]
  );

  const taxAmount = rows.reduce((_, row) => {
    const tax = taxes.find(
      (t) => Number(t.id) === Number(basket[row.agentServicesField]?.result?.data.tax_id)
    ) || { amount: 0 };
    return tax.amount;
  }, 0);

  const [sumTax, sumTaxCurrency] = rows.reduce(
    (prev, row) => {
      const currency = basket[row.id]?.currencyId || '';
      return [prev[0] + row.sumTax, currency];
    },
    [0, 0]
  );

  const [total, totalCurrency] = rows.reduce(
    (prev, row) => {
      const currency = basket[row.id]?.currencyId || '';
      return [prev[0] + row.total, currency];
    },
    [0, 0]
  );

  return {
    id: Math.random().toString(),
    type: 'result',
    cost,
    course: rows[0]?.course ?? 0,
    coursePurchase,
    coursePurchaseCurrency,
    currencyTransfer,
    currencyTransferCurrency,
    exchangeControl,
    exchangeControlCurrency,
    agentServices,
    agentServicesCurrency,
    totalNoTax,
    totalNoTaxCurrency,
    taxAmount,
    sumTax,
    sumTaxCurrency,
    total,
    totalCurrency,
  };
};
