import { styled } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';
import useWidget from '@pages/AdditionalServices/context';
import { LevelInner } from '@pages/AdditionalServices/context/types';
import { getAddServiceItems, getDirItems } from '@pages/MainWidget/helpers';
import React, { useEffect, useMemo } from 'react';

import { getTemplateParams } from '../AdditionalServiceContainer/components/AdditionalServiceIsVariantListing/components/AdditionalServicesTable/helpers';
import { sortServicesByOrderCallback } from '../AdditionalServiceContainer/components/AdditionalServiceIsVariantListing/helpers';

interface Props {
  trees: LevelInner;
  index: number;
}

const Container = styled('div')`
  width: 200px;
`;

const AdditionalFormItem = ({ trees, index }: Props) => {
  const { useAdditionalFormTrees, useEndSearch } = useWidget();
  const setTrees = useAdditionalFormTrees().setState;
  const setEndSearch = useEndSearch().setState;
  const { entityId, options = [] } = trees;
  const value = options.find((o) => o.value === entityId);

  const sortedOptions = useMemo(() => {
    const optionsCopy = options.slice();
    const sortedOptions = optionsCopy.sort((a, b) =>
      sortServicesByOrderCallback(a.entity, b.entity)
    );

    const countOfDefaultOrder = sortedOptions.filter((item) => {
      const { order } = getTemplateParams(item.entity.template);
      return order === 0;
    }).length;

    const defaultOrderOptions = sortedOptions.slice(0, countOfDefaultOrder);
    const sortedByOrderOptions = sortedOptions.slice(countOfDefaultOrder);

    return [...sortedByOrderOptions, ...defaultOrderOptions];
  }, [options]);

  const setEntityId = (id: any) => {
    setEndSearch(false);

    const value = options.find((o) => o.value === id);

    if (value?.entity?.is_variants) {
      setEndSearch(true);
      setTrees((prevState) => [...prevState.slice(0, index), { ...trees, entityId: id }]);

      return;
    }

    if (id) {
      getDirItems([id]).then((d) => {
        if (d.length !== 0) {
          const dir = d[0];
          const dirOptions = dir.childs.map((s) => ({
            value: s.id,
            label: s.name,
            entity: s,
          }));

          if (dirOptions.length !== 0) {
            setTrees((prev) => [
              ...prev.slice(0, index),
              { ...trees, entityId: id },
              {
                options: dirOptions,
                level: trees.level + 1,
                entityId: null,
              },
            ]);

            return;
          }

          setTrees((prevState) => [...prevState.slice(0, index), { ...trees, entityId: id }]);
        }

        getAddServiceItems(id).then((s) => {
          if (s.length !== 0) {
            setEndSearch(true);
            setTrees((prevState) => [...prevState.slice(0, index), { ...trees, entityId: id }]);
          }
        });
      });
    } else {
      setTrees((prevState) => [...prevState.slice(0, index), { ...trees, entityId: id }]);
    }
  };

  const onChange = (event: SelectChangeEvent) => setEntityId(event.target.value);

  useEffect(() => {
    if (options.length === 1) {
      !value && setEntityId(options[0].entity.id);
    }
  }, [options]);

  return (
    <Container>
      <FormControl variant="outlined" fullWidth={true}>
        <Select
          value={value?.value || ''}
          MenuProps={{
            disableScrollLock: true,
            disablePortal: true,
          }}
          onChange={onChange}
        >
          {sortedOptions.map((option) => (
            <MenuItem value={option.value} key={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Container>
  );
};

export default AdditionalFormItem;
