import { GridRenderCellParams } from '@mui/x-data-grid/models/params/gridCellParams';
import useWidget from '@pages/AdditionalServices/context';
import FieldCellContainer from '@pages/AdditionalServices/tabs/AdditionalForm/components/AdditionalServiceContainer/components/AdditionalServiceIsVariantListing/components/AdditionalServicesTable/components/StyledComponents';
import React from 'react';

const TaxField = (props: GridRenderCellParams<any, any, any>) => {
  const { row } = props;
  const { templateConfig } = row;
  const [basket] = useWidget().useBasketAdditionalService().useState();
  const [{ taxes }] = useWidget().useAdditionalInfo().useState();

  const result = basket[row.id];

  const styles = templateConfig?.find((config) => config.field === props.field)?.styles ?? '';

  const currentTax = taxes.find((t) => Number(t.id) === Number(result?.result?.data.tax_id));

  if (!currentTax) {
    return <FieldCellContainer>-</FieldCellContainer>;
  }

  return (
    <>
      <style>{` .taxRate-cell-${row.id} { ${styles} } `}</style>
      <FieldCellContainer className={`taxRate-cell-${row.id}`}>
        <FieldCellContainer>{currentTax.amount}%</FieldCellContainer>
      </FieldCellContainer>
    </>
  );
};

export default TaxField;
