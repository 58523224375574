import ListIcon from '@components/icons/List';
import BitrixUserContext from '@context/useBitrixUser';
import useMyRouting from '@context/useMyRouting/useMyRouting';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { RouteWidgetBaseParameters } from '@pages/AdditionalServices/tabs/DeliveryForm/types';
import ComplexSummary from '@pages/Basket/components/ServicesList/components/OrderList/components/OrderListItem/components/ComplexSummary';
import ServicesSummaryItem from '@pages/Basket/components/ServicesList/components/OrderList/components/OrderListItem/components/ServicesSummary/components/ServicesSummaryItem';
import { OrderProduct } from '@services/requests/orderService/orderProductsLoader/interface';
import { ProductSummaryProps, RouteResult } from '@services/requests/orderService/types';
import getEnv from '@settings/getEnv';
import dayjs from 'dayjs';
import React, { ComponentType, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import useBasket from '../../../../../../useBasket';
import OrderListItemStyledContainer from '../StyledComponent';
import RouteSummary from './components/RouteSummary';
import { getActionClassName } from './helpers';

/**
 * Пропсы компонента
 */
type Props = {
  onDetailClick: (product: OrderProduct) => void;
  product: OrderProduct;
  currencyCode: string;
  inFavorites?: boolean;
  additionData: RouteWidgetBaseParameters;
  langId: string;
};

/**
 * Компонент вывода одной услуги
 * @param props
 */
const OrderListItem = (props: Props) => {
  const { user } = useContext(BitrixUserContext);

  const { inFavorites, product, onDetailClick, currencyCode, langId } = props;
  const {
    useProductsIds,
    actions: { deleteFromCart },
  } = useBasket();
  const [productsIds, setProductsIds] = useProductsIds().useState();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation(['OrderList', 'Common', 'Actions']);
  const [settings] = useMyRouting().useSettings().useState();
  const { countryCode } = settings;

  const data: RouteResult = JSON.parse(product.data);

  const validDateText = useMemo(() => {
    if (data.route) {
      const dates = data.route.routes
        .map((r) => r.route.steps)
        .reduce((acc, steps) => [...acc, ...steps], [])
        .map((s) => s.shoulderOffer.active_to)
        .map((ds) => new Date(ds).getTime());
      const nearestDate = Math.min(...dates);
      return dayjs(nearestDate).format('DD.MM.YYYY ' + (countryCode === 'ru' ? 'г.' : ''));
    }
    return null;
  }, [data]);

  const fullPrice = Math.round(product.price * product.amount * 100) / 100;

  let Component: ComponentType<ProductSummaryProps> = () => null;

  switch (product.product_type) {
    case 'route':
      Component = RouteSummary;
      break;
    case 'complex':
      Component = ComplexSummary;
      break;
    default:
      Component = ServicesSummaryItem;
      break;
  }

  /**
   * Обработчик удаления продукта из корзины
   */
  const deleteHandler = () => deleteFromCart(product, user.id);

  const handlePdfDownload = (id: string) => {
    const { REACT_APP_PDF_VIEW_ENDPOINT } = getEnv();
    window.open(`${REACT_APP_PDF_VIEW_ENDPOINT}/pre-order/${id}`, '_blank');
  };

  const deleteIconClassName = product.product_type === 'route' ? 'close' : 'close service-close';

  const deleteIcon = (
    <IconButton
      color={'secondary'}
      onClick={deleteHandler}
      size={'small'}
      className={deleteIconClassName}
    >
      <CloseOutlinedIcon />
    </IconButton>
  );

  const fullPriceComponent = (
    <Typography variant={'body1'} className="price">
      {fullPrice.toLocaleString()} {currencyCode}
    </Typography>
  );

  const actions = (
    <Box className={'icon-button-block'}>
      {/* Кнопки закомментированы, т.к. нет пока логики */}
      {/*<Tooltip title={t('Actions:Change')}>*/}
      {/*  <IconButton*/}
      {/*    className="icon-button"*/}
      {/*    color={inFavorites ? 'secondary' : 'inherit'}*/}
      {/*    size={'small'}*/}
      {/*  >*/}
      {/*    <EditIcon />*/}
      {/*  </IconButton>*/}
      {/*</Tooltip>*/}
      {/*<Tooltip title={t('Actions:ToFavorites')}>*/}
      {/*  <IconButton*/}
      {/*    className="icon-button"*/}
      {/*    color={inFavorites ? 'secondary' : 'inherit'}*/}
      {/*    size={'small'}*/}
      {/*  >*/}
      {/*    <FavoriteIcon />*/}
      {/*  </IconButton>*/}
      {/*</Tooltip>*/}
      <Tooltip title={t('Common:Detailing')}>
        <IconButton
          className="icon-button"
          color={inFavorites ? 'secondary' : 'inherit'}
          size={'small'}
          onClick={() =>
            isMobile ? handlePdfDownload(product.id as string) : onDetailClick(product)
          }
        >
          <ListIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );

  const productChecked = productsIds.includes(product.id);

  const onSelectProductHandle = () => {
    const ids = productChecked
      ? productsIds.filter((p) => p !== product.id)
      : [...productsIds, product.id];
    setProductsIds(ids);
  };

  const wrapperClassName = product.product_type === 'route' ? '' : 'order-item__service-content';
  const actionsClassName = getActionClassName(product);

  const serviceParentName =
    product.product_type === 'complex' &&
    JSON.parse(JSON.parse(product.data)[0].data).serviceParent;

  return (
    <OrderListItemStyledContainer elevation={3}>
      <div>
        {isMobile && (
          <div className={'mobile-actions'}>
            {actions}
            {deleteIcon}
          </div>
        )}
        <Checkbox
          className={'route-select'}
          checked={productChecked}
          onChange={onSelectProductHandle}
        />
      </div>
      <div className={wrapperClassName}>
        {serviceParentName && product.product_type === 'complex' && (
          <div className={'service-title'}>
            {serviceParentName.localized_names.find((m) => m.lang_id === langId)?.message ||
              serviceParentName.name}
            {!isMobile && actions}
          </div>
        )}
        <Component
          product={product}
          orderCurrency={'1'}
          currencyCode={currencyCode}
          langId={langId}
          products={[]}
        />
        {product.product_type === 'route' && (
          <Typography variant={'h4'} className="calc-dates">
            {t('OrderList:itemCalcDate')} {validDateText}
          </Typography>
        )}
      </div>
      <div className={actionsClassName}>
        {product.product_type === 'route' && fullPriceComponent}
        {!isMobile && ['route', 'service'].includes(product.product_type) && actions}
      </div>
      {!isMobile && deleteIcon}
    </OrderListItemStyledContainer>
  );
};

export default OrderListItem;
