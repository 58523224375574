import { getTemplateParams } from '@pages/AdditionalServices/tabs/AdditionalForm/components/AdditionalServiceContainer/components/AdditionalServiceIsVariantListing/components/AdditionalServicesTable/helpers';
import { sortServicesByOrderCallback } from '@pages/AdditionalServices/tabs/AdditionalForm/components/AdditionalServiceContainer/components/AdditionalServiceIsVariantListing/helpers';
import { OrderProduct } from '@services/requests/orderService/orderProductsLoader/interface';

export function getSortedOrderComplexServices(products: OrderProduct[]) {
  const parsedProducts = products.map((p) => JSON.parse(p.data));
  const allParentIds = products.map((p) => JSON.parse(p.data).service.service.parent) as string[];

  const sortedRootServices = parsedProducts
    .filter((s) => {
      const rootServiceId = s.service.service_id;

      return allParentIds.includes(rootServiceId) || s.service.service.childs?.length === 0;
    })
    .sort((a, b) => sortServicesByOrderCallback(a.service.service, b.service.service));

  const countOfDefaultOrder = sortedRootServices.filter((item) => {
    const { order } = getTemplateParams(item.service.service.template);
    return order === 0;
  }).length;

  const defaultOrderServices = sortedRootServices.slice(0, countOfDefaultOrder);
  const sortedByOrderServices = sortedRootServices.slice(countOfDefaultOrder);

  const fullSortedRootServices = [...sortedByOrderServices, ...defaultOrderServices];

  parsedProducts.forEach((service) => {
    const serviceId = service.service.service_id;
    const parentServiceId = service.service.service.parent;

    const isServiceExist =
      fullSortedRootServices.findIndex((s) => s.service.service_id === serviceId) !== -1;

    if (isServiceExist) {
      return;
    }

    const allChildServices = parsedProducts.filter(
      (s) => s.service.service.parent === parentServiceId
    );
    const sortedChildServices = allChildServices.sort((a, b) =>
      sortServicesByOrderCallback(a.service.service, b.service.service)
    );
    const foundRootServiceIndex = fullSortedRootServices.findIndex(
      (s) => s.service.service_id === parentServiceId
    );

    fullSortedRootServices.splice(foundRootServiceIndex + 1, 0, ...sortedChildServices);
  });

  return fullSortedRootServices
    .map((s) => products.find((p) => p.data === JSON.stringify(s)))
    .filter(Boolean);
}
