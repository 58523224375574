import { DefaultPlaceholder } from './Placeholders/DefaultPlaceholder';
import { Template3Placeholder } from './Placeholders/Template3Placeholder';
import { Template4Placeholder } from './Placeholders/Template4Placeholder';
import { Template5Placeholder } from './Placeholders/Template5Placeholder';

export function getPlaceholderByTemplate(template: number) {
  switch (template) {
    case 1:
    case 4:
      return Template4Placeholder;
    case 3:
      return Template3Placeholder;
    case 5:
      return Template5Placeholder;
    default:
      return DefaultPlaceholder;
  }
}
