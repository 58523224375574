import { GridColDef } from '@mui/x-data-grid';

import { AdditionalTemplateParam } from '../../../interface';
import ServicesName from '../../ServicesName';
import ConversePaymentField from './ConversePaymentField';
import CostField from './CostField';
import CourseField from './CourseField';
import SumConversePaymentField from './SumConversePaymentField';
import SumField from './SumField';
import SumTaxField from './SumTaxField';
import TaxField from './TaxField';
import TotalField from './TotalField';

export const getTemplate4Columns = (
  fieldColumns: GridColDef[],
  templateConfig: AdditionalTemplateParam[]
) => {
  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: '',
      headerAlign: 'center',
      minWidth: 200,
      sortable: false,
      renderCell: ServicesName,
    },
    {
      field: 'cost',
      headerName: 'Стоимость',
      headerAlign: 'center',
      minWidth: 220,
      sortable: false,
      renderCell: CostField,
    },
    ...fieldColumns,
    {
      field: 'sum',
      headerName: 'Сумма',
      headerAlign: 'center',
      width: 220,
      sortable: false,
      renderCell: SumField,
    },
    {
      field: 'course',
      headerName: 'Курс',
      headerAlign: 'center',
      width: 100,
      sortable: false,
      renderCell: CourseField,
    },
    {
      field: 'conversePayment',
      headerName: 'Конверс. издержки',
      headerAlign: 'center',
      width: 220,
      sortable: false,
      renderCell: ConversePaymentField,
    },
    {
      field: 'sumConversePayment',
      headerName: 'Сумма в валюте платежа с учетом конверс. изд.',
      headerAlign: 'center',
      width: 220,
      sortable: false,
      renderCell: SumConversePaymentField,
    },
    {
      field: 'taxRate',
      headerName: 'Ставка налога',
      headerAlign: 'center',
      minWidth: 120,
      sortable: false,
      renderCell: TaxField,
    },
    {
      field: 'sumTax',
      headerName: 'Сумма налога',
      headerAlign: 'center',
      flex: 1,
      minWidth: 120,
      sortable: false,
      renderCell: SumTaxField,
    },
    {
      field: 'total',
      headerName: 'Итого',
      headerAlign: 'center',
      flex: 1,
      minWidth: 120,
      sortable: false,
      renderCell: TotalField,
    },
  ];

  const templateFormattedColumns = columns.map((col, index) => {
    const config = templateConfig[index];

    if (!config || col.field !== config.field) {
      return col;
    }

    const { maxWidth, minWidth } = config;

    if (maxWidth === 0 && minWidth === 0) {
      return col;
    }

    return { ...col, minWidth, maxWidth, flex: 1 };
  });

  return templateFormattedColumns;
};
