import { ServicesFieldProps } from '@services/requests/servicesService/interface';

import { ServicesProps } from '../../../../../../../../../../../services/requests/servicesService/servicesServicesService/interface';
import { BasketAdditionalService } from '../../../../../../../../../context/types';
import { TemplateParams } from '../interface';
import {
  getColumnsForAdditionalService,
  getRowsForAdditionalService,
} from './DataForTableGenerator';
import { getSortedRowsByOrder } from './helpers';

export const getChildrenGridDataRegularTemplate = (
  childs: ServicesProps[],
  basket: BasketAdditionalService,
  templateParams: TemplateParams
) => {
  const allGroupFormula = childs
    .map((child) => child.price_offers.map((o) => o.formula).join('|'))
    .join('|');
  const allGroupFields = childs.map((child) => child.allFields).flat();

  const onlyGroupFieldsInUse = allGroupFields
    .reduce((prev, field) => {
      const settings = field.hasOwnProperty('settings') ? JSON.parse(field.settings) : false;

      const replacedBy =
        settings.hasOwnProperty('usageDependencyParams') && settings.usageDependencyParams.enabled
          ? settings.usageDependencyParams.field
          : false;

      const isPrimaryInUse = allGroupFormula.includes(field.code) && !replacedBy;
      const isEditabled = settings && settings.editable;
      const isAlreadyExist = prev.findIndex((f) => f.code === field.code) !== -1;

      if (isPrimaryInUse && isEditabled && !isAlreadyExist) {
        return [...prev, field];
      }

      return prev;
    }, [] as ServicesFieldProps[])
    .sort((a, b) => (a.code < b.code ? 1 : -1));
  const {
    additionalTemplateParams: { enabled, items },
    isSectionAdditionalServices,
    type: templateType,
  } = templateParams;
  const templateConfig = enabled ? items : [];

  return getSortedRowsByOrder(
    childs
      .reduce((acc, s) => {
        const columns = getColumnsForAdditionalService(
          s,
          templateType,
          onlyGroupFieldsInUse,
          templateConfig
        ); // Получаем колонки для текущего элемента
        const rows = getRowsForAdditionalService(
          s,
          onlyGroupFieldsInUse,
          templateParams,
          basket[s.id]
        ); // Получаем строки для текущего элемента
        const columnsString = JSON.stringify(columns);

        // Проверяем, существует ли уже объект с такими же колонками
        const existingEntry = acc.find((entry) => JSON.stringify(entry.columns) === columnsString);

        if (existingEntry) {
          // Если такой объект существует, добавляем к нему строки
          existingEntry.rows.push(...rows);
        } else {
          // Если такого объекта нет, создаем новый
          acc.push({
            rows: rows,
            columns: columns,
          });
        }

        return acc;
      }, [])
      .filter(Boolean)
  );
};
