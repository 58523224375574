import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';

import FieldCellContainer from '../../../StyledComponents';

interface UnitCellProps {
  name: string;
  helper: string;
  render: React.JSX.Element;
  style: string;
  additionClassName: string;
}

function UnitCell({ helper, name, render, additionClassName, style }: UnitCellProps) {
  return (
    <>
      <style>{style}</style>
      <FieldCellContainer className={additionClassName} style={{ height: '100%' }}>
        <FieldCellContainer
          style={{ height: '100%', padding: '0 10px' }}
          className="MuiDataGrid-cell MuiDataGrid-cell--withRightBorder MuiDataGrid-withBorderColor"
        >
          {render}
        </FieldCellContainer>
      </FieldCellContainer>
    </>
  );
}

export { UnitCell };
