import palette from '@assets/theme/palette';
import { styled } from '@mui/material';

const AdditionalServiceListingContainer = styled('div', { name: 'AdditionalServiceListing' })`
  .expand-additional-service {
    border-radius: 18px;
    background: #fff;
    border: none;
    box-shadow: 0 0 20px 0 rgba(0, 5, 115, 0.07);
    color: ${palette.light.blue[400]};
    font-size: 16px;
    font-weight: 600;
    padding-top: 13px;
    padding-bottom: 13px;

    &:hover {
      box-shadow: 0 0 20px 0 rgba(138, 141, 255, 0.28);
      border: none;
    }
  }
`;

export default AdditionalServiceListingContainer;
