import Typography from '@mui/material/Typography';
import { getFormattedPrice } from '@pages/AdditionalServices/tabs/CustomsPayments/helpers/price';
import useDelivery from '@pages/AdditionalServices/tabs/DeliveryForm/useDelivery';
import { ProductSummaryProps } from '@services/requests/orderService/types';
import clsx from 'clsx';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import ServicesSummaryItemStyledComponent from './StyledComponent';

// Компонент вывода сводной информации по одной дополнительной услуге
const AdditionalService: FC<ProductSummaryProps> = ({ product, langId, products }) => {
  const { t } = useTranslation(['AdditionalService']);

  const { useBaseDate } = useDelivery();
  const [baseData] = useBaseDate().useState();

  const allParentIds = products.map((p) => JSON.parse(p.data).service.service.parent) as string[];

  const allServiceIds = products.map((p) => JSON.parse(p.data).service.service_id) as string[];

  const serviceData = JSON.parse(product.data).service;
  const isParent = allParentIds.includes(serviceData.service_id);
  const isChild = allServiceIds.includes(serviceData.service.parent);
  const isPriceOnRequest = product.price < 0;

  const name =
    serviceData.service.localized_names_data?.find((m) => m.lang_id === langId)?.message ||
    serviceData.service.name;
  const { currencies } = baseData;
  const currencyCode = currencies.find((c) => c.id === serviceData.currency_id)?.code || 'RUB';
  return (
    <ServicesSummaryItemStyledComponent>
      <div className={clsx('services-summary-item__list', { child: isChild })}>
        <Typography variant={'h4'} className={'services-summary-item__item'}>
          <span>{name}</span>
          {!isParent && (
            <span className={'price'}>
              {isPriceOnRequest && t('AdditionalService:OnRequest')}
              {!isPriceOnRequest && `${getFormattedPrice(product.price, 2)} ${currencyCode}`}
            </span>
          )}
        </Typography>
      </div>
    </ServicesSummaryItemStyledComponent>
  );
};

// Экспортируем компонент
export default AdditionalService;
