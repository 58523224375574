import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import useWidget from '@pages/AdditionalServices/context';
import { ContractorsOptions, FieldState } from '@pages/AdditionalServices/context/types';
import AdditionalServicesTableBody from '@pages/AdditionalServices/tabs/AdditionalForm/components/AdditionalServiceContainer/components/AdditionalServiceIsVariantListing/components/AdditionalServicesTable/components/AdditionalServicesTableBody';
import AdditionalServicesTableHeader from '@pages/AdditionalServices/tabs/AdditionalForm/components/AdditionalServiceContainer/components/AdditionalServiceIsVariantListing/components/AdditionalServicesTable/components/AdditionalServicesTableHeader';
import { getChildrenGridDataTemplate1 } from '@pages/AdditionalServices/tabs/AdditionalForm/components/AdditionalServiceContainer/components/AdditionalServiceIsVariantListing/components/AdditionalServicesTable/components/getChildrenGridDataTemplate1';
import { CurrencyData } from '@services/requests/searchLoaders/currencyLoader/CurrencyLoaderQuery';
import { ServicesProps } from '@services/requests/servicesService/servicesServicesService/interface';
import { TaxData } from '@services/requests/taxLoader/TaxLoaderQuery';
import { SetterContext, StateUpdater } from '@settings/core/stateContexts/types';
import React, { FC, useState } from 'react';

import MButton from '../../../../../../../../../../assets/mui/@material-extend/MButton';
import { getChildrenGridDataRegularTemplate } from './components/getChildrenGridDataRegularTemplate';
import { getChildrenGridDataTemplate3 } from './components/getChildrenGridDataTemplate3';
import { getChildrenGridDataTemplate4 } from './components/getChildrenGridDataTemplate4';
import { getChildrenGridDataTemplate5 } from './components/getChildrenGridDataTemplate5';
import { getTotalCost } from './components/getTotalCost';
import { getTemplateParams } from './helpers';
import AdditionalServicesTableStyledContainer from './StyledComponents';

interface AdditionalServicesTableProps {
  service: ServicesProps;
  services: ServicesProps[];
  contractorsOptions: ContractorsOptions;
  taxes: TaxData[];
  currencies: CurrencyData[];
}

const AdditionalServicesTable: FC<AdditionalServicesTableProps> = (props) => {
  const { service, currencies, taxes, contractorsOptions } = props;

  const {
    useFieldStateServices,
    useSelectedSubAddServices,
    useBasketAdditionalService,
    usePaymentsCount,
    useActiveVariant,
    actions,
  } = useWidget();
  const { recalculateService } = actions;

  const [states, setStates] = useFieldStateServices().useState([...service.childs_id, service.id]);
  const [selected] = useSelectedSubAddServices().useState();
  const [basket] = useBasketAdditionalService().useState();
  const [activeVariant] = useActiveVariant().useState();
  const [paymentsCount] = usePaymentsCount().useState();
  const [isOpen, setIsOpen] = useState<boolean>(true);

  const { childs, id, fields, template } = service;
  const templateParams = getTemplateParams(template);
  const { type: templateType } = templateParams;
  const allFields = [...(activeVariant?.fields || []), ...fields];

  let childrenGridData = getChildrenGridDataRegularTemplate(childs, basket, templateParams);

  if (!childs.length && templateType === '5') {
    childrenGridData = getChildrenGridDataTemplate5(id, service, basket, templateType);
  } else if (!childs.length && templateType === '4') {
    childrenGridData = getChildrenGridDataTemplate4(id, service, basket, templateParams);
  } else if (templateType === '3') {
    childrenGridData = getChildrenGridDataTemplate3(
      id,
      service,
      basket,
      templateType,
      paymentsCount
    );
  } else if (templateType === '1') {
    childrenGridData = getChildrenGridDataTemplate1(id, service, basket, templateParams);
  }

  const [totalServiceCost, totalServiceTax] = getTotalCost(childrenGridData, templateType);

  const setState = (value: SetterContext<FieldState>) => {
    if (typeof value !== 'function') {
      setStates((prevState) => ({
        ...prevState,
        [id]: value,
      }));

      recalculateService(String(id));
      return;
    }

    const stateUpdater = value as StateUpdater<FieldState>;
    setStates((prevState) => ({
      ...prevState,
      [id]: stateUpdater(states[id]),
    }));

    recalculateService(String(id));
  };

  const inOrder = selected[service.id];
  const state = states[id];
  const ready = inOrder && isOpen;
  const entity = basket[id];
  const fieldsWithOutHidden = fields.filter((f) => f.type !== 'hidden');

  return (
    <AdditionalServicesTableStyledContainer>
      <AdditionalServicesTableHeader
        entity={entity}
        state={state}
        isOpen={isOpen}
        currentFields={fieldsWithOutHidden}
        totalServiceCost={totalServiceCost}
        totalSeriveTax={totalServiceTax}
        setIsOpen={setIsOpen}
        setState={setState}
        fields={allFields.filter((f) => f.type !== 'hidden')}
        currencies={currencies}
        contractorsOptions={contractorsOptions}
        taxes={taxes}
        service={service}
        templateParams={templateParams}
      />
      {ready &&
        childrenGridData.map(({ rows, columns }, index) => (
          <AdditionalServicesTableBody key={index} rows={rows} columns={columns} />
        ))}
      {inOrder && service.childs.length !== 0 && (
        <MButton
          onClick={() => setIsOpen(!isOpen)}
          variant={'outlined'}
          className={'trigger'}
          color={isOpen ? 'primary' : 'inherit'}
          fullWidth={true}
        >
          <span>
            {isOpen && 'Свернуть'}
            {!isOpen && 'Развернуть'}
          </span>
          {isOpen && <KeyboardArrowUpRoundedIcon sx={{ fontSize: '20px' }} />}
          {!isOpen && <KeyboardArrowDownRoundedIcon sx={{ fontSize: '20px' }} />}
        </MButton>
      )}
    </AdditionalServicesTableStyledContainer>
  );
};

export default AdditionalServicesTable;
