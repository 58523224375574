import palette from '@assets/theme/palette';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import TextField from '@mui/material/TextField';
import useWidget from '@pages/AdditionalServices/context';
import { getStateFields } from '@pages/AdditionalServices/context/helpers';
import { getServiceId } from '@pages/AdditionalServices/context/helpers';
import { FieldState } from '@pages/AdditionalServices/context/types';
import { CurrencyData } from '@services/requests/searchLoaders/currencyLoader/CurrencyLoaderQuery';
import { ServicesProps } from '@services/requests/servicesService/servicesServicesService/interface';
import { SetterContext, StateUpdater } from '@settings/core/stateContexts/types';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import MButton from '../../../../../../../../../../../../assets/mui/@material-extend/MButton';

interface Template3HeaderProps {
  service: ServicesProps;
  onClickCalculate: () => void;
  onRemoveService: () => void;
}

function Template3Header({ service, onClickCalculate, onRemoveService }: Template3HeaderProps) {
  const { id, allFields } = service;

  const { t } = useTranslation(['AdditionalService', 'Actions']);

  const {
    useAdditionalInfo,
    useFieldStateServices,
    usePaymentsCount,
    useIsCalculatingPayments,
    actions,
  } = useWidget();
  const [{ currencies }] = useAdditionalInfo().useState();
  const [states, setStates] = useFieldStateServices().useState();
  const [paymentsCount, setPaymentsCount] = usePaymentsCount().useState();
  const [isCalculating] = useIsCalculatingPayments().useState();
  const { recalculateServices } = actions;
  const serviceState = states[id];
  const currentCurrency = useMemo(() => {
    const [_, value] =
      Object.entries(serviceState).find(([key]) => {
        return key.includes('paymentCurrencyTemp3');
      }) ?? [];

    const currencyId = value?.handBookValue ?? null;

    return (
      currencies.find((currency) => {
        return +currency.id === currencyId;
      }) ?? null
    );
  }, [currencies, serviceState]);

  const foundCurrenciesField = allFields.find((field) =>
    field.code.includes('paymentCurrencyTemp3')
  );
  const selectedCurrencyIds = foundCurrenciesField?.handbook_limitations ?? [];
  const selectedCurrencies = currencies.filter((currency) =>
    selectedCurrencyIds.includes(currency.id)
  );

  useEffect(() => {
    if (!paymentsCount?.[id]) {
      setPaymentsCount((prevState) => ({
        ...prevState,
        [id]: 1,
      }));
    }
  }, [paymentsCount]);

  const setState = (value: SetterContext<FieldState>) => {
    if (typeof value !== 'function') {
      setStates((prevState) => ({
        ...prevState,
        [service.id]: value,
      }));
    }

    const allServicePaymentsIds = Object.keys(states).filter(
      (key) => (key.includes('payment') && key.includes(service.id)) || key === service.id
    );
    const stateUpdater = value as StateUpdater<FieldState>;
    setStates((prevState) => {
      const stateCopy = { ...prevState };
      for (const key of allServicePaymentsIds) {
        stateCopy[key] = stateUpdater(states[key]);
      }
      return {
        ...stateCopy,
      };
    });

    recalculateServices(allServicePaymentsIds);
  };

  const changeCurrencyHandler = (value: CurrencyData) => {
    const newCurrencyId = +value.id;

    setState((prevState: FieldState) => {
      return {
        ...prevState,
        [foundCurrenciesField.code]: {
          ...prevState[foundCurrenciesField.code],
          handBookValue: newCurrencyId,
        },
      };
    });

    onRemoveService();
  };

  const calculateServicesHandler = () => {
    const isTemplate3 = service.template === '3';

    if (isTemplate3) {
      const count = paymentsCount?.[service.id] ?? 1;
      const paymentServices = new Array(count + 1)
        .fill(service)
        .map((s, index) => ({ ...s, id: `${s.id}.payment-${index}` }))
        .slice(count);

      const formattedServices = paymentServices
        .map((s) => {
          const formattedChilds = s.childs.map((c, index) => ({
            ...c,
            id: `${getServiceId(c.id, false)}.sub-payment-${index}-${s.id}`,
          }));
          return [s, ...formattedChilds];
        })
        .flat();

      const servicesStates = formattedServices.reduce((acc, service) => {
        acc = {
          ...acc,
          [service.id]: getStateFields(service.allFields, {}),
        };

        return acc;
      }, {});
      setStates((prevState) => ({ ...prevState, ...servicesStates }));
    }

    onRemoveService();
  };

  const changePaymentsCountByStepHandler = (forward: boolean) => {
    if (forward) {
      setPaymentsCount((prevState) => ({
        ...prevState,
        [id]: (prevState?.[id] ?? 1) + 1,
      }));
    } else {
      setPaymentsCount((prevState) => {
        const count = prevState?.[id] ?? 1;
        return {
          ...prevState,
          [id]: count <= 1 ? 1 : count - 1,
        };
      });
    }

    calculateServicesHandler();
  };

  const generatePaymentsHandler = () => {
    onClickCalculate();
  };

  return (
    <>
      <FormControl variant={'outlined'} style={{ minWidth: '120px' }}>
        <FormHelperText id="count-payments" className="count-payments-helper">
          Кол-во платежей
        </FormHelperText>
        <OutlinedInput
          value={paymentsCount?.[id] ?? 1}
          id="count-payments"
          type="number"
          inputProps={{
            style: { textAlign: 'center' },
          }}
          disabled={true}
          sx={{
            '& .MuiInputBase-input.Mui-disabled': {
              WebkitTextFillColor: '#000000',
            },
            '& .MuiInputBase-root.Mui-disabled': {
              backgroundColor: '#f9f9f9',
            },
            '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
              borderColor: '#000000',
            },
            '& .MuiInputLabel-root.Mui-disabled': {
              color: 'rgba(0, 0, 0, 0.87)',
            },
          }}
          startAdornment={
            <InputAdornment position="start">
              <IconButton
                aria-label="decrease value"
                onClick={() => changePaymentsCountByStepHandler(false)}
                edge="start"
                sx={{ width: 16, height: 16, backgroundColor: 'grey.100' }}
                disabled={(paymentsCount?.[id] ?? 1) === 1}
              >
                <RemoveIcon fontSize="small" />
              </IconButton>
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="increase value"
                onClick={() => changePaymentsCountByStepHandler(true)}
                edge="end"
                sx={{
                  width: 16,
                  height: 16,
                  backgroundColor: 'grey.100',
                  marginRight: '-5px',
                }}
              >
                <AddIcon fontSize="small" />
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      <FormControl variant={'outlined'} style={{ minWidth: '120px' }}>
        <FormHelperText id="currnecy-payments" className="count-payments-helper">
          Валюта
        </FormHelperText>
        <Autocomplete
          disablePortal
          id="currnecy-payments"
          className="contract-currency"
          disableClearable
          value={currentCurrency}
          style={{ minWidth: 120 }}
          options={selectedCurrencies}
          getOptionLabel={(option) => `${option.code}`}
          onChange={(_, value) => changeCurrencyHandler(value)}
          renderInput={(params) => <TextField {...params} />}
        />
      </FormControl>
      <MButton
        onClick={generatePaymentsHandler}
        className={'add-to-card-action'}
        variant={'outlined'}
        color={'secondary'}
        fullWidth={true}
      >
        {isCalculating && (
          <CircularProgress
            size={20}
            style={{ color: palette.light.secondary.main, marginRight: '10px' }}
          />
        )}
        {t('Actions:Calculate')}
      </MButton>
    </>
  );
}

export { Template3Header };
