import { GridRenderCellParams } from '@mui/x-data-grid/models/params/gridCellParams';
import useWidget from '@pages/AdditionalServices/context';
import FieldCellContainer from '@pages/AdditionalServices/tabs/AdditionalForm/components/AdditionalServiceContainer/components/AdditionalServiceIsVariantListing/components/AdditionalServicesTable/components/StyledComponents';
import React from 'react';

const ExchangeControlField = (props: GridRenderCellParams<any, any, any>) => {
  const { row } = props;
  const [basket] = useWidget().useBasketAdditionalService().useState();
  const [{ currencies }] = useWidget().useAdditionalInfo().useState();

  const result = Object.entries(basket).find(
    ([key]) => key.includes(row.id) && key.includes(row.exchangeControlField)
  )?.[1];

  const value = result?.result?.result.basePrice || '-';

  const currentCurrency =
    currencies.find(
      (c) => Number(result?.currencyId || row.exchangeControlCurrency) === Number(c.id)
    ) || '';

  if (row.type === 'result') {
    return (
      <FieldCellContainer>
        {row.exchangeControl.toFixed(2)}
        {currentCurrency && ` ${currentCurrency.code}`}
      </FieldCellContainer>
    );
  }

  return (
    <FieldCellContainer>
      {value === '-' && value}
      {value !== '-' && value.toFixed(2)}
      {currentCurrency && ` ${currentCurrency.code}`}
    </FieldCellContainer>
  );
};

export default ExchangeControlField;
