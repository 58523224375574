import { styled } from '@mui/material';

const FieldCellContainer = styled('div', { name: 'FieldCellContainer' })`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export default FieldCellContainer;
