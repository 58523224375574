import { GridRenderCellParams } from '@mui/x-data-grid/models/params/gridCellParams';
import useWidget from '@pages/AdditionalServices/context';
import FieldCellContainer from '@pages/AdditionalServices/tabs/AdditionalForm/components/AdditionalServiceContainer/components/AdditionalServiceIsVariantListing/components/AdditionalServicesTable/components/StyledComponents';
import React from 'react';

const TaxField = (props: GridRenderCellParams<any, any, any>) => {
  const { row } = props;
  const [basket] = useWidget().useBasketAdditionalService().useState([props.row.id]);
  const [{ taxes }] = useWidget().useAdditionalInfo().useState();

  const result = basket[row.id];

  const currentTax = taxes.find(
    (t) => Number(t.id) === Number(result?.result?.data.tax_id)
  );

  if (row.type === 'result') {
    return <FieldCellContainer>{row.taxAmount}%</FieldCellContainer>;
  }

  if (!currentTax) {
    return <FieldCellContainer>-</FieldCellContainer>;
  }

  return (
    <FieldCellContainer>
      <FieldCellContainer>{currentTax.amount}%</FieldCellContainer>
    </FieldCellContainer>
  );
};

export default TaxField;
