import TextField from '@mui/material/TextField';
import { GridRenderCellParams } from '@mui/x-data-grid/models/params/gridCellParams';
import useWidget from '@pages/AdditionalServices/context';
import { FieldState } from '@pages/AdditionalServices/context/types';
import { SetterContext, StateUpdater } from '@settings/core/stateContexts/types';
import React, { useEffect, useState } from 'react';

import FieldCellContainer from '../../../StyledComponents';

const CostField = (props: GridRenderCellParams<any, any, any>) => {
  const { row } = props;
  const { useFieldStateServices, useIsCalculatingPayments, actions } = useWidget();
  const { recalculateServices } = actions;
  const setIsCalculating = useIsCalculatingPayments().setState;
  const [states, setStates] = useFieldStateServices().useState();

  let timerId: NodeJS.Timeout | null = null;

  const rootPayments = Object.entries(states).filter(
    ([key]) => key.includes('payment') && !key.includes('sub-payment') && key !== row.id
  );
  const totalSumPayments = rootPayments.reduce((prev, payment) => {
    const fieldsState = payment?.[1] ?? {};
    const formattedFieldState = Object.entries(fieldsState);
    const foundPaymentValue =
      formattedFieldState.find(([key]) => key.includes('paymentValueTemp3'))?.[1].value ?? 0;
    return prev + Number(foundPaymentValue);
  }, 0);

  const rowStates = states[row.id] ?? {};
  const agentServiceKeyIndex = row?.childServiceIdsToUpdate?.length - 1 ?? 0;
  const agentServiceId = row?.childServiceIdsToUpdate?.[agentServiceKeyIndex] ?? '';
  const agentCellStates = states[agentServiceId] ?? {};

  const filteredStates = Object.fromEntries(
    Object.entries(states).filter(([key]) => key.includes('payment'))
  );

  if (row.type === 'result') {
    const values = (Object.values(filteredStates) as any[]).reduce((prev, item) => {
      const formattedService = Object.entries(item) as any[];
      const formattedServiceKeys = Object.keys(item) as string[];
      const filterKeys = [
        'isCoursePurchaseTemp3',
        'isCurrencyTransferTemp3',
        'isExchangeControlTemp3',
        'isAgentServicesTemp3',
      ];

      const [key, value] =
        formattedService.find(([key]) => {
          const isChildService = formattedServiceKeys.some((key) =>
            filterKeys.some((k) => key.includes(k))
          );
          return key.includes('paymentValueTemp3') && !isChildService;
        }) ?? [];

      if (!key) {
        return prev;
      }

      const formattedValue = +value.value;

      return [...prev, formattedValue];
    }, [] as number[]);

    const totalSum = values.reduce((prev, value) => prev + value, 0);
    return <FieldCellContainer>{totalSum}</FieldCellContainer>;
  }

  const [costFieldName, costValue] = (Object.entries(rowStates) as any[])?.find(([key]) => {
    return key.includes('paymentValueTemp3');
  }) ?? ['', { value: '' }];

  const agentFieldName = Object.keys(agentCellStates)?.find((key) => {
    return key.includes('paymentSumTemp3');
  });

  const [value, setValue] = useState<string>(costValue.value.toString());

  const setState = (value: SetterContext<FieldState>) => {
    if (typeof value !== 'function') {
      setStates((prevState) => ({
        ...prevState,
        [row.id]: value,
      }));

      recalculateServices([row.id, ...row.childServiceIdsToUpdate]);
      return;
    }
    const stateUpdater = value as StateUpdater<FieldState>;
    setStates((prevState) => {
      return {
        ...prevState,
        [row.id]: stateUpdater(states[row.id]),
        [agentServiceId]: stateUpdater(states[agentServiceId]),
      };
    });

    recalculateServices([row.id, ...row.childServiceIdsToUpdate]);
  };

  const onChangeCost = (value: string, isDebounce: boolean) => {
    const debounceTime = isDebounce ? 500 : 0;
    setIsCalculating(true);

    if (value === '0' || value === '') {
      setValue('0');

      timerId = setTimeout(() => {
        setState((prevState: FieldState) => ({
          ...prevState,
          [costFieldName]: {
            ...prevState[costFieldName],
            value: '0',
          },
        }));
        setIsCalculating(false);
      }, debounceTime);
      return;
    }

    setValue(value);
    timerId = setTimeout(() => {
      setState((prevState: FieldState) => {
        const isAgentPayment = Object.keys(prevState).some((key) => {
          return key.includes('paymentSumTemp3');
        });
        const fieldName = isAgentPayment ? agentFieldName : costFieldName;
        const newValue = isAgentPayment ? Number(value) + totalSumPayments : value;
        return {
          ...prevState,
          [fieldName]: {
            ...prevState[fieldName],
            value: newValue,
          },
        };
      });
      setIsCalculating(false);
    }, debounceTime);
  };

  useEffect(() => {
    onChangeCost(costValue.value.toString(), false);
  }, [costValue]);

  useEffect(() => {
    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, [timerId]);

  return (
    <TextField
      fullWidth
      style={{ margin: '5px 0' }}
      size={'small'}
      InputLabelProps={{ shrink: true }}
      type={'number'}
      label={''}
      variant="outlined"
      value={value}
      onChange={(event) => onChangeCost(event.target.value, true)}
    />
  );
};

export default CostField;
